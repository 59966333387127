import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";

export default class extends Controller {
  static targets = [
    "weMakeSection",
    "meetSindiSection",
    "meetSindiTitle",
    "weMakeItem",
    "meetSindiItem",
    "featBenefitItem",
    "introduceItem",
    "requentlyAskedItem",
    "videoContent",
    "videoOverlay",
  ];

  connect() {
    this.initAnimations();
    this.modifySpacingForSwiper();
    this.initSwiper();
  }

  initSwiper() {
    Swiper.use([Navigation, Pagination]);

    this.swiper = new Swiper(this.meetSindiSectionTarget, {
        modules: [Navigation],
        spaceBetween: 32,
        slidesPerGroup: 1,
        cssMode: true,
        slidesPerView: "auto",
        centeredSlides: false,
        navigation: {
            nextEl: ".button-next",
            prevEl: ".button-prev",
        },
        loop: false,
        breakpoints: {
            320: { slidesPerView: 1.3, spaceBetween: 15 },
            768: { slidesPerView: 2.5, spaceBetween: 20 },
            1024: { slidesPerView: 2.5, spaceBetween: 30 },
            1280: { slidesPerView: 3.5, spaceBetween: 30 },
        },
        on: {
            init: () => {
                this.updateNavigationButtons();
            }
        },
    });

    this.setupNavigationEvents();
  }

  setupNavigationEvents() {
    const nextButton = this.meetSindiSectionTarget.querySelector(".button-next");
    const prevButton = this.meetSindiSectionTarget.querySelector(".button-prev");

    nextButton.addEventListener('click', () => {
        this.swiper.slideNext();
        this.updateNavigationButtons();
    });

    prevButton.addEventListener('click', () => {
        this.swiper.slidePrev();
        this.updateNavigationButtons();
    });
  }

  updateNavigationButtons() {
    if (!this.swiper) return;
    const prevButton = this.meetSindiSectionTarget.querySelector(".button-prev");
    const nextButton = this.meetSindiSectionTarget.querySelector(".button-next");

    prevButton.classList.toggle("active", !this.swiper.isBeginning);
    nextButton.classList.toggle("active", !this.swiper.isEnd);
  }

  modifySpacingForSwiper() {
    this.meetSindiTitleTarget.style.marginRight = `${this.weMakeSectionTarget.offsetLeft}px`;
    this.meetSindiSectionTarget.style.width = `calc(100% - ${this.weMakeSectionTarget.offsetLeft}px)`;

    window.addEventListener("resize", () => {
      let spaceMargin = this.weMakeSectionTarget.offsetLeft;
      this.meetSindiTitleTarget.style.marginRight = `${spaceMargin}px`;
      this.meetSindiSectionTarget.style.width = `calc(100% - ${spaceMargin}px)`;
    });
  }

  initAnimations() {
    const config = { threshold: [0] };
    const animations = {
      "weMakeItem": (_, index) => index === 0 || index === 1 ? "slide-in-left" : "slide-in-right",
      "featBenefitItem": "slide-down",
      "introduceItem": (_, index) => index % 2 === 0 ? "slide-in-right" : "slide-in-left",
      "requentlyAskedItem": "slide-in-right",
    };

    Object.keys(animations).forEach(key => {
      this[`${key}Targets`].forEach((item, index) => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            const action = typeof animations[key] === "function" ? animations[key](item, index) : animations[key];
            if (entry.isIntersecting) {
              entry.target.classList.add(action);
            } else {
              entry.target.classList.remove(action);
            }
          });
        }, config);

        observer.observe(item);
      });
    });
  }

  handlePlayVideo(event) {
    event.preventDefault();

    if (this.videoContentTarget.paused) {
      this.videoContentTarget.play();
      this.videoOverlayTarget.classList.add("d-none");
    } else {
      this.videoContentTarget.pause();
      this.videoOverlayTarget.classList.remove("d-none");
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["note", "editField", "editDeleteButtons"]

  toggleEditMode(isEditing) {
    this.noteTarget.classList.toggle("d-none", isEditing)
    this.editFieldTarget.classList.toggle("d-none", !isEditing)
    this.editDeleteButtonsTarget.classList.toggle("d-none", isEditing)
  }

  edit(event) {
    this.toggleEditMode(true)
  }

  save(event) {
    this.toggleEditMode(false)
  }

  cancel(event) {
    this.toggleEditMode(false)
  }
}

import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = [ 'itemTooth', 'itemChar' ]

  connect() {
    $(this.itemToothTargets).on( 'click', (e) => {
      $(e.currentTarget).toggleClass("checkbox-selected")
      $(e.currentTarget).find("img").toggleClass('d-none')
      var checkbox = $(e.currentTarget).find('input:checkbox')
      if(checkbox.is(':checked')){
        $(checkbox).prop('checked', false)
      } else {
        $(checkbox).prop('checked', true)
      }
    })

    $(this.itemCharTargets).on( 'click', (e) => {
      $(e.currentTarget).toggleClass("checkbox-selected")
      var checkbox = $(e.currentTarget).find('input:checkbox')
      if(checkbox.is(':checked')){
        $(checkbox).prop('checked', false)
      } else {
        $(checkbox).prop('checked', true)
      }
    })
  }
}

import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

import Select2 from "select2"

export default class extends Controller {

  connect() {
    $('.content-search').prepend('<option selected></option>').select2({
      placeholder: $(this).data('placeholder'),
      allowClear: true
    })

    $(".select2").select2({
      placeholder: "Select appointment type"
    });
  }
}

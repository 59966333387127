import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

import Select2 from "select2"
global.$.fn.select2 = $.fn.select2
require("javascripts/select2.customSelectionAdapter.min.js")

export default class extends Controller {
  static targets = [ "select2Custom", "select2Single", "select2SingleAsSearch", "select2Multiple" ]

  connect() {
    const customSelectionAdapter = $.fn.select2.amd.require("select2/selection/customSelectionAdapter");
    $('.' + this.select2CustomTarget.dataset.classSelect2Target).select2({
      selectionAdapter: customSelectionAdapter,
      selectionContainer: $('.' + this.select2CustomTarget.dataset.resultSelect2Target)
    })
    $(".select2Custom")?.select2({})
  }

  select2MultipleTargetConnected(element) {
    const customSelectionAdapter = $.fn.select2.amd.require("select2/selection/customSelectionAdapter");

    $(this.select2MultipleTarget).select2({
      selectionAdapter: customSelectionAdapter,
      selectionContainer: $('.' + this.select2CustomTarget.dataset.resultSelect2Target)
    })
  }

  select2SingleTargetConnected(element) {
    $(element).select2()
    const customSelectionAdapter = $.fn.select2.amd.require("select2/selection/customSelectionAdapter");

    $('.' + this.select2CustomTarget.dataset.classSelect2Target).select2({
      selectionAdapter: customSelectionAdapter,
      selectionContainer: $('.' + this.select2CustomTarget.dataset.resultSelect2Target)
    })

    $(element).closest('.select2-single-content').children('span.select2.select2-container').not(':first').remove()
  }

  select2SingleAsSearchTargetConnected(element) {
    $(element).select2()
  }

  handleClickDropdownOnProvider() {
    if ($(".select2-results__options>li").length > 3) {
    $(".select2-dropdown--above").css("margin-top", "-60px");
    $(".select2-dropdown--above").css("max-height", "250px");
    $(".select2-results__options").css("max-height", "250px");
    }
    $(".select2-dropdown--above").css("margin-top", "-10px");

  }
}
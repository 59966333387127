import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = ["submitBtn"]

  roleChange(e) {
    const providerFields = e.target.closest('form').querySelector('.provider-fields');
    const commonFields = e.target.closest('form').querySelector('.common-fields');
    commonFields.classList.remove('d-none');

    if (e.target.value == 'clinician') {
      providerFields.classList.remove('d-none');
      this.toggleFields(providerFields.querySelectorAll('input, select'), false);
    } else {
      providerFields.classList.add('d-none');
      this.toggleFields(providerFields.querySelectorAll('input, select'), true);
    }

    this.toggleSubmitButton();
  }

  toggleFields(fields, value) {
    if (value) {
      fields.forEach((field) => {
        field.setAttribute('disabled', '');
      });
    } else {
      fields.forEach((field) => {
        field.removeAttribute('disabled');
      });
    }
  }

  toggleSubmitButton() {
    if (!this.hasSubmitBtnTarget) return;

    const form = this.submitBtnTarget.closest('form');

    form.addEventListener('input', (_e) => {
      this.checkSubmitButton(form);
    });
    form.dispatchEvent(new Event('input'));

    form.querySelectorAll('select[required]').forEach((select) => {
      $(select).on('change', (e) => {
        this.checkSubmitButton(form);
      })
    });
  }

  submitBtnTargetConnected() {
    this.toggleSubmitButton();
  }

  checkSubmitButton(form) {
    const submitBtn = this.submitBtnTarget;
    const requiredInputFields = form.querySelectorAll('input[type=text][required]:not([disabled])');
    const requiredSelectFields = [];
    const selectFields = form.querySelectorAll('.select2-selection__rendered');
    selectFields.forEach((field) => {
      field.closest('.form-group').querySelectorAll('select[required]').forEach((select) => {
        requiredSelectFields.push(field);
      });
    });

    let allFieldsValid = true;
    requiredInputFields.forEach((field) => {
      if (field.value == '') {
        allFieldsValid = false;
      }
    });
    requiredSelectFields.forEach((field) => {
      if (field.closest('div').querySelectorAll('select:not([disabled])').length && field.innerText == '') {
        allFieldsValid = false;
      }
    });

    if (allFieldsValid) {
      submitBtn.classList.remove('btn-lock');
    } else {
      submitBtn.classList.add('btn-lock');
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import { checkAvailableForSubmitBtn } from 'helpers'

export default class extends Controller {
  static targets = [ "input" ,"inputHidden" , "dropzone"]

  inputTargetConnected(e) {
    e.addEventListener("input", (e) => {
      const searchInput = e.target.value.toLowerCase();
      $('.recipient-item').map(function() {
        const optionText = this.textContent.toLowerCase();
        if (optionText.includes(searchInput)) {
          this.style.display = '';
        } else {
          this.style.display = 'none';
        }
      })
    })

    e.addEventListener("focus", () => {
      this.dropzoneTarget.classList.remove("d-none")
    });

    e.addEventListener("focusout", (e) => {
      setTimeout(() => {
        const selectedRecipient = $('.hover-provider')
        if (selectedRecipient.length && this.hasInputTarget) {
          this.inputTarget.value = selectedRecipient.get(0).dataset.selectValue
        }
        if (e.target.classList.contains('not-found')) {
          $(e.target).val('');
          $('#referral_referred_provider_id').val('')
          $('#referral_referred_pending_provider_id').val('')
          e.target.classList.remove('not-found')
          $('.recipient-item').removeClass("d-none")
          checkAvailableForSubmitBtn($(e.target))
        }
        this.dropzoneTarget.classList.add("d-none")
      },200)
    });
  }

  commonSelect(e, targetIndex, clearIndex = null) {

    this.inputTarget.value = e.currentTarget.dataset.selectValue;
    this.inputHiddenTargets[targetIndex].value = e.currentTarget.dataset.selectValueId;
    if (clearIndex !== null) {
      this.inputHiddenTargets[clearIndex].value = '';
    }

    $('ul.dz-search-input li').removeClass('hover-provider');
    e.currentTarget.classList.add('hover-provider');
    checkAvailableForSubmitBtn($(this.inputTarget));
  }

  selectProvider(e) {
    this.commonSelect(e, 0, 1);
  }

  selectPatient(e) {
    this.commonSelect(e, 0);
  }

  selectInvitation(e) {
    this.commonSelect(e, 1, 0);
  }

  selectExistingPatient(e) {
    window.location.assign("?patient_id=" + e.currentTarget.id)
  }

  selectState(e) {
    this.inputTarget.value = e.currentTarget.dataset.selectValue
    checkAvailableForSubmitBtn($(this.inputTarget))
  }
}

import { Controller } from "@hotwired/stimulus";
import { checkNewReferralFormSubmitButton } from "../helpers";

export default class extends Controller {
  static targets = [
    "referralForm",
    "inputOrganizationWrapper",
    "inputOrganization",
    "hiddenInputOrganizationId",
    "dropzone",
    "referredOrganizationDzItems",
    "referredOrganizationFieldsWrapper",
    "addNewOrganizationBtn",
    "chooseExistingOrganizationBtn",
    "inputReferredProviderWrapper",
    "inputReferredProvider",
    "hiddenInputReferredProviderId",
    "chooseExistingReferredProviderBtn",
    "addNewReferredProviderBtn",
    "referredProviderBtnsWrapper",
    "addReferredProviderWrapper",
    "referredProviderFieldsWrapper",
    "anyProviderRadioBtn",
    "submitBtn",
    "referredProviderListDropzone",
    "referredProviderDzItems",
    "newOrganizationEmail",
    "specificProviderRadioBtn",
    "addEmail",
  ];

  initReferredProviderBtnsWrapper() {
    this.referredProviderBtnsWrapperTarget.classList.remove("d-none");
    this.addNewReferredProviderBtnTarget.classList.remove("d-none");
    this.chooseExistingReferredProviderBtnTarget.classList.add("d-none");
  }

  getReferredProviders(organizationId, providerId = null) {
    this.referredProviderDzItemsTarget.innerHTML = "";
    $.ajax({
      url: `/referrals/providers_by_organization`,
      type: "GET",
      dataType: "HTML",
      data: { organization_id: organizationId },
      success: (data) => {
        this.referredProviderDzItemsTarget.innerHTML = data;
        this.setHoverReferredProviderSelection();

        const providerItems =
          this.referredProviderDzItemsTarget.querySelectorAll("li");
        const providerItem =
          providerItems.length === 1
            ? providerItems[0]
            : this.referredProviderDzItemsTarget.querySelector(
                `li[data-select-id="${providerId}"]`
              );

        if (providerItem) this.setReferredProvider(providerItem);
      },
    });
  }

  setReferredProvider(referredProviderEl) {
    this.inputReferredProviderTarget.value =
      referredProviderEl.dataset.selectLabel;
    this.hiddenInputReferredProviderIdTarget.value =
      referredProviderEl.dataset.selectId;

    referredProviderEl
      .closest("ul")
      .querySelectorAll("li")
      .forEach((li) => li.classList.remove("hover-selection"));
    referredProviderEl.classList.add("hover-selection");

    this.checkSubmitButton();
    checkNewReferralFormSubmitButton();
  }

  setHoverReferredOrganizationSelection() {
    this.referredOrganizationDzItemsTarget
      .querySelectorAll("li")
      .forEach((li) => {
        li.classList.remove("hover-selection");
        if (li.dataset.selectId == this.hiddenInputOrganizationIdTarget.value)
          li.classList.add("hover-selection");
      });
  }

  setHoverReferredProviderSelection() {
    this.referredProviderDzItemsTarget.querySelectorAll("li").forEach((li) => {
      li.classList.remove("hover-selection");
      if (li.dataset.selectId == this.hiddenInputReferredProviderIdTarget.value)
        li.classList.add("hover-selection");
    });
  }

  inputReferredProviderTargetConnected(e) {
    if (this.hiddenInputOrganizationIdTarget.value.length) {
      this.getReferredProviders(this.hiddenInputOrganizationIdTarget.value);
    }

    e.addEventListener("focus", () => {
      this.referredProviderListDropzoneTarget.classList.remove("d-none");
    });

    e.addEventListener("focusout", (e) => {
      setTimeout(() => {
        this.referredProviderListDropzoneTarget.classList.add("d-none");
      }, 200);
    });
  }

  referralFormTargetConnected() {
    if (this.hiddenInputOrganizationIdTarget.value) {
      this.checkSubmitButton();
      checkNewReferralFormSubmitButton();

      this.referralFormTarget.addEventListener("input", () => {
        this.checkSubmitButton();
        checkNewReferralFormSubmitButton();
      });

      this.disableReferredOrganizationNewFields(true);
      this.disableReferredProviderNewFields(true);
    }

    if (this.newOrganizationEmailTarget.value) {
      this.addNewOrganizationClick();

      this.checkSubmitButton(
        true,
        this.newOrganizationEmailTarget.value.trim()
      );
      checkNewReferralFormSubmitButton();
    }
  }

  addNewOrganizationClick() {
    this.referredOrganizationFieldsWrapperTarget.classList.remove("d-none");
    this.chooseExistingOrganizationBtnTarget.classList.remove("d-none");
    this.addNewOrganizationBtnTarget.classList.add("d-none");
    this.referredProviderBtnsWrapperTarget.classList.add("d-none");
    this.addReferredProviderWrapperTarget.classList.add("d-none");
    this.submitBtnTarget.classList.add("btn-lock");

    this.hiddenInputReferredProviderIdTarget.value = "";
    this.hiddenInputOrganizationIdTarget.value = "";

    this.disableReferredProviderType(false);
    this.disableReferredOrganizationIdInput(true);
    this.disableReferredOrganizationNewFields(false);
    this.checkReferredProviderType();

    this.newOrganizationEmailTarget.addEventListener("input", () => {
      this.checkSubmitButton(
        true,
        this.newOrganizationEmailTarget.value.trim()
      );
      checkNewReferralFormSubmitButton();
    });

    this.newOrganizationEmailTarget.value = this.inputOrganizationTarget.value
  }

  addNewReferredProviderBtnClick() {
    this.disableReferredProviderIdInput(true);
    this.disableReferredProviderNewFields(false);
    this.addNewReferredProviderBtnTarget.classList.add("d-none");
    this.chooseExistingReferredProviderBtnTarget.classList.remove("d-none");
    this.checkSubmitButton();
    checkNewReferralFormSubmitButton();
  }

  inputOrganizationTargetConnected(e) {
    e.addEventListener("focusout", (e) => {
      setTimeout(() => {
        // Set the input organization value to the selected organization name until the user deletes all the characters.
        if (this.hiddenInputOrganizationIdTarget.value.length) {
          e.target.value =
            this.hiddenInputOrganizationIdTarget.dataset.organizationName;
        }
        this.dropzoneTarget.classList.add("d-none");
      }, 200);
    });
  }

  searchOrganizations(e) {
    setTimeout(() => {
      const searchText = e.target.value;

      if (!searchText.length) {
        // Set the hidden referred organization id and the hidden referred provider id value to empty.
        this.hiddenInputOrganizationIdTarget.value = "";
        this.hiddenInputReferredProviderIdTarget.value = "";
        // Hidden all the fields.
        this.chooseExistingOrganizationBtnTarget.classList.add("d-none");
        this.addReferredProviderWrapperTarget.classList.add("d-none");
        this.dropzoneTarget.classList.add("d-none");
        // Check the submit button.
        this.checkSubmitButton();
        checkNewReferralFormSubmitButton();
        return;
      }

      $.ajax({
        url: `/referrals/referred_organizations`,
        type: "GET",
        dataType: "HTML",
        data: { search_text: searchText },
        success: (data) => {
          this.referredOrganizationDzItemsTarget.innerHTML = data;
          this.setHoverReferredOrganizationSelection();
          this.addEmailTarget.innerHTML = "Add " + searchText;
        },
      });

      this.dropzoneTarget.classList.remove("d-none");
    }, 300);
  }

  fetchOrganizations(e) {
    $.ajax({
      url: `/referrals/referred_organizations`,
      type: "GET",
      dataType: "HTML",
      data: e.target.value ? { search_text: e.target.value } : {},
      success: (data) => {
        this.referredOrganizationDzItemsTarget.innerHTML = data;
        this.setHoverReferredOrganizationSelection();
      },
    });
    this.dropzoneTarget.classList.remove("d-none");
  }

  disableReferredProviderNewFields(value) {
    if (value) {
      this.referredProviderFieldsWrapperTarget.classList.add("d-none");
      this.referredProviderFieldsWrapperTarget
        .querySelectorAll("input")
        .forEach((input) => (input.disabled = true));
    } else {
      this.referredProviderFieldsWrapperTarget.classList.remove("d-none");
      this.referredProviderFieldsWrapperTarget
        .querySelectorAll("input")
        .forEach((input) => (input.disabled = false));
    }
  }

  disableReferredProviderType(value) {
    if (value) {
      this.specificProviderRadioBtnTarget.checked = true;
      this.anyProviderRadioBtnTarget.checked = false;
    } else {
      this.anyProviderRadioBtnTarget.checked = false;
      this.specificProviderRadioBtnTarget.checked = false;
    }
  }

  disableReferredOrganizationNewFields(value) {
    if (value) {
      this.referredOrganizationFieldsWrapperTarget
        .querySelectorAll("input")
        .forEach((input) => (input.disabled = true));
    } else {
      this.referredOrganizationFieldsWrapperTarget
        .querySelectorAll("input")
        .forEach((input) => (input.disabled = false));
    }
  }

  disableReferredOrganizationIdInput(value) {
    if (value) {
      this.inputOrganizationWrapperTarget.classList.add("d-none");
      this.hiddenInputOrganizationIdTarget.disabled = true;
      this.hiddenInputOrganizationIdTarget.value = "";
    } else {
      this.inputOrganizationWrapperTarget.classList.remove("d-none");
      this.hiddenInputOrganizationIdTarget.disabled = false;
    }
  }

  disableReferredProviderIdInput(value) {
    if (value) {
      this.inputReferredProviderWrapperTarget.classList.add("d-none");
      this.hiddenInputReferredProviderIdTarget.disabled = true;
      this.hiddenInputReferredProviderIdTarget.value = "";
      this.inputReferredProviderTarget.value = "";
    } else {
      this.inputReferredProviderWrapperTarget.classList.remove("d-none");
      this.hiddenInputReferredProviderIdTarget.disabled = false;
    }
  }

  checkReferredProviderType() {
    if (this.anyProviderRadioBtnTarget.checked) {
      this.disableReferredProviderIdInput(true);
      this.disableReferredProviderNewFields(true);
      this.referredProviderBtnsWrapperTarget.classList.add("d-none");
    } else {
      if (this.hiddenInputOrganizationIdTarget.value.length) {
        this.disableReferredProviderIdInput(false);
        this.referredProviderBtnsWrapperTarget.classList.remove("d-none");
        this.disableReferredProviderNewFields(true);
        this.initReferredProviderBtnsWrapper();
      } else {
        this.disableReferredProviderIdInput(true);
        this.disableReferredProviderNewFields(false);
      }
    }
    this.checkSubmitButton()
    checkNewReferralFormSubmitButton();
  }

  selectOrganizationClick(e) {
    const { organizationId, organizationName, providerId } =
      e.currentTarget.dataset;

    this.inputReferredProviderTarget.value = "";
    this.hiddenInputReferredProviderIdTarget.value = "";

    this.getReferredProviders(organizationId, providerId);

    this.hiddenInputOrganizationIdTarget.value = organizationId;
    this.hiddenInputOrganizationIdTarget.dataset.organizationName =
      organizationName;

    e.currentTarget
      .closest("ul")
      .querySelectorAll("li")
      .forEach((li) => li.classList.remove("hover-selection"));
    e.currentTarget.classList.add("hover-selection");

    this.addReferredProviderWrapperTarget.classList.remove("d-none");

    this.initReferredProviderBtnsWrapper();
    this.disableReferredProviderType(true);
    this.disableReferredOrganizationNewFields(true);
    this.disableReferredProviderNewFields(true);
    this.disableReferredProviderIdInput(false);
    this.checkReferredProviderType();
    this.checkSubmitButton();
    checkNewReferralFormSubmitButton();
  }

  selectReferredProviderClick(e) {
    this.disableReferredOrganizationNewFields(true);
    this.setReferredProvider(e.currentTarget);
    checkNewReferralFormSubmitButton();
  }

  chooseExistingReferredProviderBtnClick() {
    this.disableReferredProviderIdInput(false);
    this.disableReferredProviderNewFields(true);
    this.chooseExistingReferredProviderBtnTarget.classList.add("d-none");
    this.addNewReferredProviderBtnTarget.classList.remove("d-none");
    this.checkSubmitButton();
    checkNewReferralFormSubmitButton();
  }

  chooseExistingOrganizationClick() {
    this.referredOrganizationFieldsWrapperTarget.classList.add("d-none");
    this.chooseExistingOrganizationBtnTarget.classList.add("d-none");
    this.addReferredProviderWrapperTarget.classList.add("d-none");
    this.addNewOrganizationBtnTarget.classList.remove("d-none");

    this.newOrganizationEmailTarget.value = null;
    this.disableReferredProviderType(true);

    this.disableReferredOrganizationIdInput(false);
    this.disableReferredOrganizationNewFields(true);
    this.checkReferredProviderType();
    this.checkSubmitButton();
    checkNewReferralFormSubmitButton();
  }

  checkSubmitButton(skipValidation = false, isValidateEmail = false) {
    if (skipValidation) {
      this.submitBtnTarget.classList.toggle("btn-lock", !isValidateEmail);
    } else {
      setTimeout(() => {
        const requiredFields = this.referralFormTarget.querySelectorAll(
          "input[type=text][required]:not([disabled]), input[type=hidden][required]:not([disabled])"
        );
        const isAnyUnfilledFields = [...requiredFields].some(
          (field) => !field.value.trim()
        );

        this.submitBtnTarget.classList.toggle("btn-lock", isAnyUnfilledFields);
      }, 200);
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'
import { checkAvailableForSubmitBtn } from 'helpers'

export default class extends Controller {
  static targets = [ 'submitBtn']

  connect() {
    checkAvailableForSubmitBtn($(this.submitBtnTarget))
    $(this.submitBtnTarget).closest('form').on("change", () => {
      checkAvailableForSubmitBtn($(this.submitBtnTarget))
    })
  }
}

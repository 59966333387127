import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

export default class extends Controller {

  connect() {
    $('.phone-format').on('input', function(e) {
      const input = e.target.value.replace('+1 ','').replace(/\D/g, '').substring(0,10);
      const lastChar = input.slice(-1);
      if (!lastChar.match(/\d/)) {
        e.target.value = input.slice(0, -1);
      } else {
        const areaCode = input.substring(0,3);
        const middle = input.substring(3,6);
        const last = input.substring(6,10);
        if(input.length > 6){e.target.value = `+1 (${areaCode}) ${middle}-${last}`;}
        else if(input.length > 3){e.target.value = `+1 (${areaCode}) ${middle}`;}
        else if(input.length > 0){e.target.value = `+1 (${areaCode}`;}
      }
    });
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["note", "count", "submitMessage"]

  connect() {
    this.updateCount()
  }

  updateCount() {
    this.countTarget.textContent = `${this.noteTarget.value.length}/750`
    if (this.noteTarget.value.length > 0) {
      this.submitMessageTarget.classList.add('active');
      this.submitMessageTarget.disabled = false;
    } else {
      this.submitMessageTarget.classList.remove('active');
      this.submitMessageTarget.disabled = true;
    }
  }
}

import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { checkFiles } from "../helpers";

export default class extends Controller {
  static targets = ["photographsFileField"];

  connect() {
    this.ongoingUploads = 0;
  }

  handleChange(e) {
    if (!e.target) return;

    const files = e.target.files;
    const [selectedFiles, dataTransferFiles] = checkFiles(files);

    $("#target").empty();

    if ($("#target img").length + files.length > 5) {
      alert("You can only upload a maximum of 5 files");
      e.target.value = '';
      return;
    }

    selectedFiles.forEach((file, index) => {
      const uniqueId = `uploading-${Date.now()}-${index}`;
      this.appendImageWrapper(uniqueId);
      file.name.endsWith(".dcm")
        ? this.parseAndDisplayDicomFile(file, uniqueId)
        : this.loadAndDisplayImage(file, uniqueId);
    });

    e.target.files = dataTransferFiles;
  }

  appendImageWrapper(uniqueId) {
    const wrapperHtml = `
      <div class="image-wrapper col-5 col-lg-3 px-0" id="${uniqueId}">
        <div class="d-flex justify-content-center align-items-center" style="height: 220px">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>`;
    $("#target").append(wrapperHtml);
  }

  loadAndDisplayImage(file, uniqueId) {
    const reader = new FileReader();
    reader.onload = (event) => this.displayImage(event.target.result, uniqueId);
    reader.readAsDataURL(file);
  }

  displayImage(imageSrc, uniqueId) {
    const imageHtml = `
      <img src="${imageSrc}" class="h-100 w-100">
      <div class="close-wrapper">
        <i class="icon-close" data-action="click->upload#removeImage"></i>
      </div>`;
    $(`#${uniqueId}`).empty().append(imageHtml);
  }

  parseAndDisplayDicomFile(file, uniqueId) {
    this.setButtonState(true);
    this.ongoingUploads++;
    const formData = new FormData();
    formData.append("dicom_file", file);

    $.ajax({
      url: "/dicom/convert",
      type: "POST",
      beforeSend: (xhr) =>
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        ),
      data: formData,
      processData: false,
      contentType: false,
      success: (data) => this.handleSuccess(data, uniqueId),
      error: (error) => this.handleError(error, uniqueId),
    });
  }

  handleSuccess(data, uniqueId) {
    if (data.url) {
      this.displayImage(data.url, uniqueId);
    } else {
      alert("Error processing file");
    }
    this.finalizeUpload();
  }

  handleError(error, uniqueId) {
    console.error("Error:", error);
    $(`#${uniqueId}`).remove();
    this.finalizeUpload();
  }

  finalizeUpload() {
    this.ongoingUploads--;
    if (this.ongoingUploads === 0) {
      this.setButtonState(false);
    }
  }

  setButtonState(disabled) {
    $(".btn-continue").prop("disabled", disabled);
  }

  handleUploadAvatar(e) {
    const file = e.target?.files[0];
    const [selectedFiles] = checkFiles([file]);

    if (!selectedFiles.length) {
      e.target.value = '';
      return;
    }

    const reader = new FileReader();
    reader.onload = () =>
      $("img.avatar-provider-img").attr("src", reader.result);
    reader.readAsDataURL(file);
  }

  handleClick(e) {
    $(e.target).val("");
  }

  removeImage(e) {
    e.currentTarget.closest(".image-wrapper").remove();
    this.photographsFileFieldTarget.value = "";
  }
}

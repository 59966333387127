import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["testSoundAlert", "newNotification"];

  playTestSoundAlert() {
    this.testSoundAlertTarget.play();
  }

  newNotificationTargetConnected(el) {
    el.play().then(() => {
      // Auto-play was successful
    }).catch((error) => {
      console.log(error);
    });
  }
}

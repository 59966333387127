export function loadStripe(publicKey) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    script.onload = function () {
      try {
        const { stripe, cardNumberElement } = initializeStripe(publicKey);
        resolve({ stripe, cardNumberElement });
      } catch (error) {
        reject(error);
      }
    };
    script.onerror = function (error) {
      reject(error);
    };
    document.head.appendChild(script);
  });
}

function initializeStripe(publicKey) {
  const stripe = Stripe(publicKey);
  const elements = stripe.elements({
    mode: 'setup',
    currency: 'usd',
    fonts: [
      {
        cssSrc: "https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;500;600;700;800&display=swap",
      },
    ],
  });

  const style = {
    classes: { base: 'form-control' },
    style: {
      base: {
        fontFamily: 'Nunito Sans, sans-serif',
      },
    }
  };

  const cardNumberElement = elements.create('cardNumber', style);
  cardNumberElement.mount('#cardNumber');
  cardNumberElement.on('change', function(event) {
    const displayError = document.getElementById('card-number-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  });

  const cardExpiryElement = elements.create('cardExpiry', style);
  cardExpiryElement.mount('#cardExpiry');
  cardExpiryElement.on('change', function(event) {
    const displayError = document.getElementById('card-expiry-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  });

  const cardCvcElement = elements.create('cardCvc', style);
  cardCvcElement.mount('#cardCvc');
  cardCvcElement.on('change', function(event) {
    const displayError = document.getElementById('card-cvc-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  });

  return { stripe, cardNumberElement };
}

export function createStripePaymentMethod(
  stripe,
  cardNumberElement,
  email,
  billingName,
  billingCountry
) {
  return stripe.createPaymentMethod({
    type: 'card',
    card: cardNumberElement,
    billing_details: {
      email: email,
      name: billingName,
      address: {
        country: billingCountry,
      },
    },
  });
}

export function createPaymentMethodSubmitForm(stripePaymentMethod, isDefault = false) {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = '/admin/payment_methods';
  form.style.display = 'none';

  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = 'stripe_payment_method';
  input.value = JSON.stringify(stripePaymentMethod);

  const defaultInput = document.createElement('input');
  defaultInput.type = 'hidden';
  defaultInput.name = 'default';
  defaultInput.value = isDefault;

  form.appendChild(input);
  form.appendChild(defaultInput);

  document.body.appendChild(form);

  return form;
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["editToggleButton", "editActionsWrapper", "nameInput", "organizationInputWrapper", "stateInput", "stateDropzone"]

  nameInputTargetConnected(e) {
    e.addEventListener("focusin", () => this.handleShowEditActions('true'));
    e.addEventListener("focusout", () => this.handleShowEditActions('false'));
  }

  refreshOrganizationNameInput() {
    this.handleShowEditActions('false')
    this.nameInputTarget.value = '';
  }

  handleShowEditActions(value) {
    if (value === 'true') {
      this.editActionsWrapperTarget.classList.remove('d-none');
      this.editToggleButtonTarget.classList.add('d-none');
      this.organizationInputWrapperTarget.classList.add('input-border');
    } else {
      this.editActionsWrapperTarget.classList.add('d-none');
      this.editToggleButtonTarget.classList.remove('d-none');
      this.organizationInputWrapperTarget.classList.remove('input-border');
    }
  }

  stateInputTargetConnected(e) {
    e.addEventListener("input", (e) => {
      const searchInput = e.target.value.toLowerCase();
      $('.recipient-item').map(function() {
        const optionText = this.textContent.toLowerCase();
        if (optionText.includes(searchInput)) {
          this.style.display = '';
        } else {
          this.style.display = 'none';
        }
      })
    })

    e.addEventListener("focus", () => {
      this.stateDropzoneTarget.classList.remove("d-none")
    });

    e.addEventListener("focusout", () => {
      setTimeout(() => {
        const selectedRecipient = $('.hover-provider')

        if (selectedRecipient.length && this.hasStateInputTarget) {
          this.stateInputTarget.value = selectedRecipient.get(0).dataset.selectValue
        }

        this.stateDropzoneTarget.classList.add("d-none")
      },200)
    });
  }

  selectState(e) {
    this.stateInputTarget.value = e.currentTarget.dataset.selectValue
  }
}
import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'
import { Tooltip } from 'bootstrap'
export default class extends Controller {
  static targets = [
    "addItem",
    "template",
    // "submitBtn",
    "clearForm",
    "patientConsentCheckBox",
    // "submitWrapper",
    "checkboxWrapper",
    "patientIdHidden",
    "patientInput",
    "patientDzItemsWrapper",
    "patientDzItems",
    "patientInputWrapper"
  ]

  connect() {
    this.checkInputFilled($(this.submitBtnTarget));
    $(this.submitBtnTarget).closest('form').on("change", () => {
      this.checkInputFilled($(this.submitBtnTarget))
    });
    window.addEventListener('resize', () => {
      this.checkInputFilled($(this.submitBtnTarget))
    });
    this.initializeConsentCheckbox();

    const urlParams = new URLSearchParams(window.location.search);
    // TODO: Need to refactor this to avoid to reload whole page.
    if(!urlParams.get('patient_id') && !urlParams.get('referral_id')) {
      $('#btnAddMorePatient').click();
    }
  }

  initializeConsentCheckbox() {
    if (!this.hasPatientConsentCheckBoxTarget) return;

    this.patientConsentCheckBoxTarget.addEventListener('change', () => {
      this.checkInputFilled($(this.submitBtnTarget));
    });
  }

  checkInputFilled($el) {
    const $form = $el.closest('form');
    const submitBtn = $form.find('.btn-submit');
    const inputDropdown = $('#referral_patient_name');
    const isBirthdayDesktop = $('.row.mt-2.d-block.d-md-none').is(':hidden');
    const addMorePatientElement = $('#btnAddMorePatient');
    const submitWrapper = this.submitWrapperTarget;

    const inputs = isBirthdayDesktop  ? $form.find("input[type='text'][required]:not(#referral_patient_attributes_date_of_birth)")
                                      : $form.find("input[type='text'][required]").add("input[type='date']");

    //If value the dropdown existed, enable the submit button
    if(inputDropdown.val() !== '' || this.patientInputWrapperTarget.classList.contains('d-none')) {
      addMorePatientElement.addClass('d-none');
      $(".btn-clear").removeClass('d-none');
    } else {
      addMorePatientElement.removeClass('d-none');
      $(".btn-clear").addClass('d-none');
    }

    const checkInputs = () => {
      if (!this.hasPatientConsentCheckBoxTarget) {
        this.disableButtonTooltip();
        return;
      }

      const consentCheckBox = this.patientConsentCheckBoxTarget;
      const checkboxWrapper = this.checkboxWrapperTarget;
      const isFieldsPatientPresent = $('#fieldsPatient').length;
      const allInputsFilled = inputs.toArray().every(input => input.value !== '') && consentCheckBox.checked && isFieldsPatientPresent;

      if (!consentCheckBox.checked && isFieldsPatientPresent) {
        submitWrapper.setAttribute("title", "Please click the checkbox to confirm this patient gave their consent for Sindi to reach out");
        submitWrapper.setAttribute("data-bs-toggle", "tooltip");
        submitWrapper.setAttribute("data-bs-placement", "bottom");

        new Tooltip(submitWrapper)

        submitWrapper.addEventListener("show.bs.tooltip", function () {
          checkboxWrapper.classList.add("checkbox-required");
        });
        submitWrapper.addEventListener("hidden.bs.tooltip", function () {
          checkboxWrapper.classList.remove("checkbox-required");
        });
      } else {
        this.disableButtonTooltip();
      }

      submitBtn.toggleClass('bg-light-silver', !allInputsFilled)
        .toggleClass('bg-light-primary', allInputsFilled)
        .prop('disabled', !allInputsFilled);
    };

    inputs.on('input', checkInputs).trigger('input');
    inputDropdown.on('change', checkInputs).trigger('input');
  }

  disableButtonTooltip() {
    const tooltipInstance = Tooltip.getInstance(this.submitWrapperTarget);
    tooltipInstance && tooltipInstance.dispose();
  }

  addAssociationForPatient(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf());
    const newElement = document.createElement('div');
    const $submitBtn = $(".btn-submit");
    const fieldsPatient = $('#fieldsPatient');

    newElement.innerHTML = content;
    newElement.style.display = 'none';
    fieldsPatient.html(newElement);
    $(newElement).slideDown(200);
    $('input[type=text]').val('');
    $('#dropdownPatient').slideUp(200);
    $('#btnAddMorePatient').addClass('d-none');
    this.addItemTarget.insertAdjacentElement('beforebegin', newElement);
    $submitBtn.addClass('bg-light-silver').prop("disabled", true);
  }

  removeAssociationForPatient(event) {
    event.preventDefault();
    const wrapper = event.target.closest('#fieldsPatient');
    const $submitBtn = $(".btn-submit");

    wrapper.remove();
    $('#dropdownPatient').slideDown(200);
    $('#fieldsPatient').slideUp(200, () => $('#fieldsPatient').remove());
    this.patientInputWrapperTarget.classList.remove('d-none');
    $(".btn-clear").addClass('d-none');
    $('#btnAddMorePatient').removeClass('d-none');
    $submitBtn.addClass('bg-light-silver').prop("disabled", true);
    this.disableButtonTooltip();
  }
  handleClearForm(inputDropdown) {
    if (inputDropdown.val() !== '') {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('patient_id');
      urlParams.delete('referral_id');
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
      window.location.reload();
    }
  }

  clearFormTargetConnected() {
    const clearForm = $(this.clearFormTarget);
    const clearBtn = $('.btn-clear');
    const clearText = $('.btn-text');

    clearForm.hover(
      () => clearText.toggleClass('d-none'),
      () => clearText.toggleClass('d-none')
    );

    clearBtn.click(() => {
      this.handleClearForm($('#referral_patient_name'));
    });
  }

  patientInputTargetConnected(e) {
    e.addEventListener("focusout", (e) => {
      setTimeout(() => {
        this.patientDzItemsWrapperTarget.classList.add("d-none");
      }, 200)
    });
  }

  searchPatients(e){
    setTimeout(() => {
      const searchText = e.target.value;

      if (!searchText.length) {
        this.patientDzItemsWrapperTarget.classList.add("d-none");
        return;
      }

      $.ajax({
        url: `/referrals/patients_by_organization`,
        type: 'GET',
        dataType: 'HTML',
        data: { search_text: searchText },
        success: (data) => {
          this.patientDzItemsTarget.innerHTML = data;
        }
      });

      this.patientDzItemsWrapperTarget.classList.remove("d-none");
    }, 300);
  }

  selectExistingPatient(e) {
    this.patientIdHiddenTarget.value = e.currentTarget.dataset.id;
    this.patientInputTarget.value = e.currentTarget.dataset.label;

    window.location.assign("?patient_id=" + e.currentTarget.dataset.id)
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  saveAsDraft() {
    const newReferralForm = document.querySelector('#new_referral_form');
    const urlParams = new URLSearchParams(window.location.search);
    const referringReferralId = urlParams.get('referring_referral_id')

    newReferralForm.action = `/referrals/save_as_draft?referring_referral_id=${referringReferralId}`;
    newReferralForm.requestSubmit();
  }
}

import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = [
    "step",
    "onboardingModal",
    "addTeamMemberModal",
    "stepIndicator",
    "stepIndicatorMobile",
    "providerFields",
    "commonFields",
    "submitSentInviteBtn",
    "addTeamMemberFormWrapper",
    "addTeamMemberSuccess",
    "formAddTeamMember",
    "video",
    "nextStepButton",
    "continueButton",
  ];

  emailInvite = "";

  connect() {
    this.isUpdatedViewedIntroductionVideo = false;

    this.hasVideoTarget && this.initializeVideoProgress();
    this.hasOnboardingModalTarget && this.initializeModal();
  }

  initializeVideoProgress() {
    const video = this.videoTarget;
    video.addEventListener("timeupdate", () => {
      if (
        video.currentTime / video.duration > 0.5 &&
        !this.isUpdatedViewedIntroductionVideo
      ) {
        this.updateIsViewedIntroductionVideo();
      }
    });
  }

  updateIsViewedIntroductionVideo() {
    $.ajax({
      url: `/users/update_viewed_introduction_video`,
      type: "PATCH",
      data: { viewed_introduction_video: true },
      success: () => {
        this.isUpdatedViewedIntroductionVideo = true;
        console.log("Introduction video viewing status updated.");
      },
      error: (error) => {
        console.error("Error updating viewed introduction video:", error);
      },
    });
  }

  initializeModal() {
    const skipOnboardingSession =
      this.onboardingModalTarget.dataset.skipOnboardingSesstionValue;

    if (!skipOnboardingSession) {
      $(this.onboardingModalTarget).modal("show");
      this.showCurrentStep(0);
    }
  }

  removeOnboardingModal() {
    $(this.onboardingModalTarget)
      .modal("hide")
      .on("hidden.bs.modal", () => {
        $.ajax({
          url: "/admin/users",
          data: { skip_onboarding_session: true },
          type: "GET",
          success: () => {
            window.location.href = "/plans";
          },
          error: (error) => {
            console.error("Error skipping onboarding:", error);
            alert("Something went wrong. Please try again.");
          },
        });
      });
  }

  handleSkipOnboarding() {
    $.ajax({
      url: "/users/update_skip_onboarding",
      data: { skip_onboarding: true },
      type: "PATCH",
      success: () => {
        this.removeOnboardingModal();
      },
      error: (error) => {
        console.error("Error skipping onboarding:", error);
        alert("Something went wrong. Please try again.");
      },
    });
  }

  showCurrentStep(index) {
    this.stepTargets.forEach((el, i) => {
      el.classList.toggle("d-none", i !== index);
    });
    this.currentIndex = index;
    this.highlightIndicator(index);
    this.toggleNavigationButtons(index);
  }

  toggleNavigationButtons(index) {
    if (index === 3) {
      this.nextStepButtonTarget.classList.add("d-none");
      this.continueButtonTarget.classList.remove("d-none");
    } else {
      this.nextStepButtonTarget.classList.remove("d-none");
      this.continueButtonTarget.classList.add("d-none");
    }
  }

  formAddTeamMemberTargetConnected() {
    this.toggleSubmitButton();
  }

  nextStep() {
    if (this.currentIndex < this.stepTargets.length - 1) {
      this.showCurrentStep(this.currentIndex + 1);
    }

    localStorage.setItem("onboardingStep", this.currentIndex + 1);
  }

  showStep(event) {
    const index = parseInt(event.target.dataset.index, 10);
    this.showCurrentStep(index);

    if (index === 3) {
      this.nextStepButtonTarget.classList.add("d-none");
      this.continueButtonTarget.classList.remove("d-none");
    } else {
      this.nextStepButtonTarget.classList.remove("d-none");
      this.continueButtonTarget.classList.add("d-none");
    }

    localStorage.setItem("onboardingStep", index);
  }

  highlightIndicator(index) {
    const isMobile = window.innerWidth < 768;
    let indicators

    if (isMobile) {
      indicators = this.stepIndicatorMobileTargets;
    } else {
      indicators = this.stepIndicatorTargets;
    }

    indicators.forEach((ind, idx) => {
      if (idx == index) {
        ind.classList.add("active");
      } else {
        ind.classList.remove("active");
      }
    });
  }

  handleBackToOnboardingModal() {
    $("#onboardingModal").modal("show");
    $(this.addTeamMemberModalTarget).modal("hide");
  }

  roleChange(e) {
    const providerFields = this.providerFieldsTarget;
    const commonFields = this.commonFieldsTarget;
    const isClinician = e.target.value == "clinician";

    commonFields.classList.remove("d-none");

    if (isClinician) {
      providerFields.classList.remove("d-none");
      this.toggleFields(
        providerFields.querySelectorAll("input, select"),
        false
      );
    } else {
      providerFields.classList.add("d-none");
      this.toggleFields(providerFields.querySelectorAll("input, select"), true);
    }

    this.toggleSubmitButton();
  }

  toggleFields(fields, value) {
    if (value) {
      fields.forEach((field) => {
        field.setAttribute("disabled", "");
      });
    } else {
      fields.forEach((field) => {
        field.removeAttribute("disabled");
      });
    }
  }

  toggleSubmitButton() {
    if (!this.hasSubmitSentInviteBtnTarget) return;

    const form = this.submitSentInviteBtnTarget.closest("form");

    form.addEventListener("input", (_e) => {
      const submitBtn = this.submitSentInviteBtnTarget;
      const requiredInputFields = form.querySelectorAll(
        "input[type=text][required]:not([disabled])"
      );
      const requiredSelectFields = form.querySelectorAll(
        ".select2-selection__rendered"
      );

      let allFieldsValid = true;
      requiredInputFields.forEach((field) => {
        if (field.value == "") {
          allFieldsValid = false;
        }
      });
      requiredSelectFields.forEach((field) => {
        if (
          field.closest("div").querySelectorAll("select:not([disabled])")
            .length &&
          field.innerText == ""
        ) {
          allFieldsValid = false;
        }
      });

      if (allFieldsValid) {
        submitBtn.classList.remove("btn-lock");
      } else {
        submitBtn.classList.add("btn-lock");
      }
    });

    form.dispatchEvent(new Event("input"));
  }

  onStartSubmitFormAddTeamMember(event) {
    this.emailInvite = event.target.querySelector(
      "input[name='user[email]'"
    ).value;
  }

  onEndSubmitFormAddTeamMember(event) {
    if (event.detail.success) {
      this.addTeamMemberFormWrapperTarget.classList.add("d-none");
      this.addTeamMemberSuccessTarget.querySelector(".email-invite").innerText =
        this.emailInvite;
      this.addTeamMemberSuccessTarget.classList.remove("d-none");

      setTimeout(() => {
        this.addTeamMemberFormWrapperTarget.classList.remove("d-none");
        this.addTeamMemberSuccessTarget.classList.add("d-none");
        this.formAddTeamMemberTarget.reset();

        this.formAddTeamMemberTarget
          .querySelectorAll(".select2-selection__rendered")
          .forEach((el) => {
            el.innerText = "";
          });

        this.toggleSubmitButton();

        this.roleChange({ target: { value: "clinician" } }, true);
      }, 3000);
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import { loadStripe, createStripePaymentMethod, createPaymentMethodSubmitForm } from 'helpers/stripe_element';

export default class extends Controller {
  static values = {
    publicKey: String
  }

  static targets = ["email", "billingName", "billingCountry", "default"]

  connect() {
    loadStripe(this.publicKeyValue).then(({ stripe, cardNumberElement }) => {
      this.stripe = stripe;
      this.cardNumberElement = cardNumberElement;
    }).catch(error => {
      console.error('Error loading Stripe:', error);
    });
  }

  savePaymentMethod() {
    createStripePaymentMethod(
      this.stripe,
      this.cardNumberElement,
      this.emailTarget.value,
      this.billingNameTarget.value,
      this.billingCountryTarget.value,
    ).then((result) => {
      if (result.error) {
        console.error('Stripe error:', result.error.message);
      } else {
        this.submitPaymentMethod(result.paymentMethod, this.defaultTarget.checked);
      }
    }).catch((error) => {
      console.error('Error creating Stripe payment method:', error);
    });
  }

  submitPaymentMethod(stripePaymentMethod, isDefault) {
    const form = createPaymentMethodSubmitForm(stripePaymentMethod, isDefault);
    form.requestSubmit();
  }
}

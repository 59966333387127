import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import FlatpickrShortcuts from "shortcut-buttons-flatpickr";

export default class extends Controller {
  static targets = [
    "offcanvasTimeline",
    "offcanvasTimelineSidebar",
    "dropdownStatus",
    "datepicker",
    "sendAsvBtn",
    "timepicker",
    "submitBtnDateTime",
    "referredProviderDropdownMenu",
    "appointmentStatus",
    "closeReferral",
  ]

  connect() {
    $("tr[data-link]").click(function () {
      $.ajax({
        dataType: "script",
        url: $(this).data("link"),
      });
    });

    if (this.hasTimepickerTarget) {
      this.populateTimepicker();
      $(this.timepickerTarget).val(this.timepickerTarget.dataset.value)
    }

    if (this.hasDatepickerTarget) {
      this.initDatepicker();
    }
    if (this.hasAppointmentStatusTarget) {
      $('.appointment-type').val(this.appointmentStatusTarget.dataset.value).select2()
    }
  }

  toggleTimelineText(event) {
    let container = event.currentTarget.closest(".nested-comment-content");
    if (container) {
      let noteTimeline = container.querySelector(".note-timeline");
      noteTimeline.classList.toggle("truncate");
      event.currentTarget.textContent =
        event.currentTarget.textContent.includes("Show More")
          ? "Show Less"
          : "Show More";
    }
  }

  initDatepicker() {
    const datepickerElement = $(this.datepickerTarget);
    const defaultDate = this.datepickerTarget.dataset.value

    flatpickr(datepickerElement, {
      defaultDate: defaultDate,
      allowInput: true,
      altInput: true,
      altFormat: "M j, Y",
      plugins: [
        FlatpickrShortcuts({
          button: [
            {
              label: "Clear",
            },
          ],
          onClick: (index) => {
            switch (index) {
              case 0:
                datepickerElement[0]._flatpickr.clear();
                datepickerElement[0]._flatpickr.close();
                break;
            }
          },
        }),
      ],
      onChange: this.checkDateTimeSelected.bind(this),
    });
  }

  populateTimepicker() {
    const timepickerElement = this.timepickerTarget;
    const increment = 15;
    timepickerElement.innerHTML = '';
    timepickerElement.innerHTML += `<option value="" selected>Select a time</option>`;

    for (let hour = 6; hour <= 18; hour++) {
      for (let minute = 0; minute < 60; minute += increment) {
        if (hour === 18 && minute > 0) {
            break;
        }
        const timeString = this.formatTime12Hour(hour, minute);
        timepickerElement.innerHTML += `<option value="${timeString}">${timeString}</option>`;
      }
    }
    timepickerElement.addEventListener('change', this.checkDateTimeSelected.bind(this));
  }

  saveDateTime() {
    const appointmentDate = this.datepickerTarget.value;
    const appointmentTime = this.timepickerTarget.value;
    const referralId = this.datepickerTarget.dataset.referralDetailId;
    const appointmentStatus = this.appointmentStatusTarget.value;

    if (appointmentDate && appointmentTime) {
      const dateTimeString = `${appointmentDate} ${appointmentTime}`;
      this.updateAppointmentDateTime(dateTimeString, referralId, appointmentStatus);
    }
  }

  updateAppointmentDateTime(dateTimeString, referralId, appointmentStatus) {
    $.ajax({
      method: "PUT",
      url: `/referrals/${referralId}/update_appointment_date`,
      data: { appointment_date: dateTimeString, status: appointmentStatus},
      success: (data) => {
        window.location.reload();
      },
    });

    this.checkDateTimeSelected();
  }

  formatTime12Hour(hour, minute) {
    const period = hour < 12 ? "AM" : "PM";
    let adjustedHour = hour % 12;
    if (adjustedHour === 0) {
      adjustedHour = 12;
    }
    const hourString = adjustedHour.toString().padStart(2, '0');
    const minuteString = minute.toString().padStart(2, '0');
    return `${hourString}:${minuteString} ${period}`;
  }

  checkDateTimeSelected() {
    const appointmentDate = this.datepickerTarget.value;
    const appointmentTime = this.timepickerTarget.value;
    const submitButton = this.submitBtnDateTimeTarget;
    if (appointmentDate && appointmentTime) {
      submitButton.classList.remove('btn-lock');
      submitButton.classList.add('btn-active');
    } else {
      submitButton.classList.remove('btn-active');
      submitButton.classList.add('btn-lock');
    }
  }

  dropdownStatusTargetConnected() {
    const referralStatus = this.dropdownStatusTarget.innerText.trim();

    if (referralStatus === "Completed") {
      this.sendAsvBtnTarget.classList.remove("d-none");
    } else {
      this.sendAsvBtnTarget.classList.add("d-none");
    }
  }

  closeReferral(e){
    this.closeReferralTarget.classList.add("btn-lock");
  }

  activateCloseReferral(e) {
    $('[data-referral-detail-target="closeReferral"]').removeClass("btn-lock")
  }

  toggleOffcanvasTimeline(event) {
    this.offcanvasTimelineTarget.style.display = 'block';
    this.offcanvasTimelineTarget.classList.add('offcanvas-open');
    this.offcanvasTimelineSidebarTarget.classList.add('d-none');
  }

  closeOffcanvasTimeline() {
    this.offcanvasTimelineTarget.classList.remove('offcanvas-open');
    this.offcanvasTimelineTarget.style.display = 'none';
    this.offcanvasTimelineSidebarTarget.classList.remove('d-none');
  }

  changeReferredProviderDropdownMenuText(e) {
    this.referredProviderDropdownMenuTarget.innerHTML = e.target.innerText;
  }
}

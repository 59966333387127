import { Controller } from "@hotwired/stimulus"
import { loadStripe, createStripePaymentMethod, createPaymentMethodSubmitForm } from 'helpers/stripe_element';

export default class extends Controller {
  static values = {
    publicKey: String
  }

  static targets = [
    "monthlyPlanOption",
    "yearlyPlanOption",
    "monthlyPlan",
    "yearlyPlan",
    "email",
    "billingName",
    "billingCountry",
    "default",
    "upgradeButton",
    "processingButton",
    "successButton",
    "selectMyPaymentMethod",
    "cardSection",
    "checkoutCouponForm",
    "billingPeriod",
    "couponCode"
  ]

  connect() {
    loadStripe(this.publicKeyValue).then(({ stripe, cardNumberElement }) => {
      this.stripe = stripe;
      this.cardNumberElement = cardNumberElement;
    }).catch(error => {
      console.error('Error loading Stripe:', error);
    });
  }

  selectMyPaymentMethodTargetConnected(e) {
    e.addEventListener("change", () => {
      if(e.value == 'pm_new') {
        $(this.cardSectionTarget).removeClass('d-none')
      }else{
        $(this.cardSectionTarget).addClass('d-none')
      }
    });
  }

  selectBillingCycle(event) {
    this.togglePlan(event.target.dataset.billingCycle);
    this.billingPeriodTarget.value = event.target.dataset.billingCycle
    this.submitCheckCouponForm()
  }

  togglePlan(value = 'monthly') {
    if (value === 'monthly') {
      this.monthlyPlanOptionTarget.classList.add('checked');
      this.yearlyPlanOptionTarget.classList.remove('checked');
      this.monthlyPlanTargets.forEach((element) => {
        element.classList.remove('d-none');
      });
      this.yearlyPlanTargets.forEach((element) => {
        element.classList.add('d-none');
      });
    } else {
      this.yearlyPlanOptionTarget.classList.add('checked');
      this.monthlyPlanOptionTarget.classList.remove('checked');
      this.yearlyPlanTargets.forEach((element) => {
        element.classList.remove('d-none');
      });
      this.monthlyPlanTargets.forEach((element) => {
        element.classList.add('d-none');
      });
    }
  }

  subscribe() {
    this.toggleButton('processing');

    const paymentId =  $('input[type=radio][name=payment_method_id]:checked').val() || 'pm_new'
    if(paymentId != 'pm_new') {
      this.checkoutSubscription(paymentId, false);
      return;
    }

    createStripePaymentMethod(
      this.stripe,
      this.cardNumberElement,
      this.emailTarget.value,
      this.billingNameTarget.value,
      this.billingCountryTarget.value,
    ).then((result) => {
      if (result.error) {
        console.error('Stripe error:', result.error.message);
        this.toggleButton('upgrade');
      } else {
        this.submitPaymentMethod(result.paymentMethod);
      }
    }).catch((error) => {
      console.error('Error creating Stripe payment method:', error);
      this.toggleButton('upgrade');
    });
  }

  submitPaymentMethod(stripePaymentMethod) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const form = createPaymentMethodSubmitForm(stripePaymentMethod);
    const formData = new FormData(form);
    const params = new URLSearchParams(formData);

    fetch(form.action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': csrfToken
      },
      body: params
    })
    .then(response => {
      if (response.ok) {
        this.checkoutSubscription(stripePaymentMethod.id);
      } else {
        console.error('Save payment method failed:', response.statusText);
        this.toggleButton('upgrade');
      }
    })
    .catch(error => {
      console.error('Error submitting payment method:', error);
      this.toggleButton('upgrade');
    })
    .finally(() => {
      document.body.removeChild(form);
    });
  }

  checkoutSubscription(stripe_payment_method_id, isNewPaymentMethod = true) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = '/subscriptions/checkout';
    form.style.display = 'none';

    const planInput = document.createElement('input');
    planInput.type = 'hidden';
    planInput.name = 'plan_id';
    planInput.value = document.querySelector('.billing-cycle-option.checked').dataset.planId;
    form.appendChild(planInput);

    const stripePaymentMethodInput = document.createElement('input');
    stripePaymentMethodInput.type = 'hidden';
    stripePaymentMethodInput.name = 'stripe_payment_method_id';
    stripePaymentMethodInput.value = stripe_payment_method_id;
    form.appendChild(stripePaymentMethodInput);

    const isNewPaymentMethoInput = document.createElement('input');
    isNewPaymentMethoInput.type = 'hidden';
    isNewPaymentMethoInput.name = 'is_new_payment_method';
    isNewPaymentMethoInput.value = isNewPaymentMethod;
    form.appendChild(isNewPaymentMethoInput);

    const contactInformationEmailInput = document.createElement('input');
    contactInformationEmailInput.type = 'hidden';
    contactInformationEmailInput.name = 'contact_information_email';
    contactInformationEmailInput.value = this.emailTarget.value;
    form.appendChild(contactInformationEmailInput);

    const couponCodeInput = document.createElement('input');
    couponCodeInput.type = 'hidden';
    couponCodeInput.name = 'coupon_code';
    couponCodeInput.value = this.couponCodeTarget.value;
    form.appendChild(couponCodeInput);

    document.body.appendChild(form);

    fetch(form.action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': csrfToken
      },
      body: new URLSearchParams(new FormData(form))
    })
    .then(response => {
      if (response.ok) {
        this.redirectToSuccessCheckoutPage();
      } else {
        console.error('Create subscription failed:', response.statusText);
        this.toggleButton('upgrade');
      }
    })
    .catch(error => {
      console.error('Error creating subscription:', error);
      this.toggleButton('upgrade');
    })
    .finally(() => {
      document.body.removeChild(form);
    });
  }

  toggleButton(buttonName) {
    if (buttonName === 'upgrade') {
      this.upgradeButtonTarget.classList.remove('d-none');
      this.processingButtonTarget.classList.add('d-none');
      this.successButtonTarget.classList.add('d-none');
    } else if (buttonName === 'processing') {
      this.upgradeButtonTarget.classList.add('d-none');
      this.processingButtonTarget.classList.remove('d-none');
      this.successButtonTarget.classList.add('d-none');
    } else if (buttonName === 'success') {
      this.upgradeButtonTarget.classList.add('d-none');
      this.processingButtonTarget.classList.add('d-none');
      this.successButtonTarget.classList.remove('d-none');
      this.successButtonTarget.classList.add('slide-down');
    }
  }

  redirectToSuccessCheckoutPage() {
    this.toggleButton('success')

    setTimeout(() => {
      const link = document.createElement('a');
      link.href = '/subscriptions/success';
      document.body.appendChild(link);
      link.click();
    }, 1500);
  }

  submitCheckCouponForm() {
    this.checkoutCouponFormTarget.requestSubmit();
  }
}

import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["submitBtn", "invitationForm"];

  connect() {
    this.checkFormCompletion();
  }

  submitBtnTargetConnected() {
    this.checkFormCompletion();
  }

  checkFormCompletion() {
    const inputEls = Array.from(
      this.invitationFormTarget.querySelectorAll("input")
    );

    inputEls.forEach((el) => {
      el.addEventListener("input", this.handleInput);
    });

    this.updateButtonState();
  }

  handleInput = () => {
    this.updateButtonState();
  };

  updateButtonState() {
    const isComplete = this.isFormComplete();
    $(".btn-send").toggleClass("complete", isComplete);
  }

  isFormComplete() {
    const inputEls = Array.from(
      this.invitationFormTarget.querySelectorAll("input")
    );
    return inputEls.every((el) => $(el).val().trim() !== "");
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["additionalNote", "characterCount", "submitBtn", "denialReasonHiddenField", "denialReasonBtn"];

  countCharacters() {
    const count = this.additionalNoteTarget.value.length;
    this.characterCountTarget.innerText = `${count}/750`;
  }

  updateSubmitButtonStyle(e) {
    if (e.target.classList.contains('selected')) {
      e.target.classList.remove('selected');
      this.denialReasonHiddenFieldTarget.value = '';
      this.submitBtnTarget.classList.remove('btn-active');
      this.submitBtnTarget.classList.add('btn-lock');
    } else {
      this.denialReasonBtnTargets.forEach(btn => btn.classList.remove('selected'));
      this.denialReasonHiddenFieldTarget.value = e.target.dataset.value;
      e.target.classList.add('selected');
      this.submitBtnTarget.classList.remove('btn-lock');
      this.submitBtnTarget.classList.add('btn-active');
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "emailInput",
    "providerListDropzone",
    "providerDzItems",
    "addEmail",
    "addEmailWrapper",
    "existingEmailWrapper",
    "existingProviderName",
    "newReferralPath",
    "noteInputWrapper",
    "noteInput",
    "submitButton",
    "form",
  ];

  emailInputTargetConnected(e) {
    e.addEventListener("focusout", (e) => {
      if (!!!this.providerDzItemsTarget.children.length && this.emailInputTarget.value.length) {
        this.noteInputWrapperTarget.classList.remove("d-none");
      } else {
        this.noteInputWrapperTarget.classList.add("d-none");
      }

      this.checkSubmitButton();

      setTimeout(() => {
        this.providerListDropzoneTarget.classList.add("d-none");
      }, 200);
    });
  };

  searchProviders(e) {
    setTimeout(() => {
      this.providerListDropzoneTarget.classList.remove("d-none");
      this.existingEmailWrapperTarget.classList.add("d-none");
      const searchText = e.target.value;

      this.checkSubmitButton();

      if (!searchText.length) {
        this.providerListDropzoneTarget.classList.add("d-none");
        this.resetAndHideNoteInput();
      } else {
        $.ajax({
          url: '/searchs/providers',
          type: "POST",
          dataType: "HTML",
          data: { search_text: searchText },
          success: (data) => {
            this.providerDzItemsTarget.innerHTML = data;
            if (data) {
              this.providerItemClick();
              this.addEmailWrapperTarget.classList.add("d-none");
            } else {
              this.addEmailWrapperTarget.classList.remove("d-none");
              this.addEmailTarget.innerHTML = searchText;
            }
          },
        });

        this.providerListDropzoneTarget.classList.remove("d-none");
      }
    }, 300);
  }

  providerItemClick() {
    document.querySelectorAll("li.provider-item").forEach((item) => {
      item.addEventListener("click", () => {
        this.emailInputTarget.value = item.dataset.providerEmail;
        this.existingProviderNameTarget.innerHTML = item.dataset.providerName;
        this.newReferralPathTarget.attributes.href.value = item.dataset.newReferralPath;
        this.existingEmailWrapperTarget.classList.remove("d-none");
      });
    });
  }

  resetAndHideNoteInput() {
    this.noteInputWrapperTarget.classList.add("d-none");
    this.checkSubmitButton();
  }

  noteInputEvent(e) {
    this.checkSubmitButton();
  }

  checkSubmitButton() {
    if (!!!this.providerDzItemsTarget.children.length && this.noteInputTarget.value.length) {
      this.formTarget.dataset.preventSubmit = "false";
      this.submitButtonTarget.classList.remove("btn-lock");
      this.submitButtonTarget.disabled = false;
    } else {
      this.formTarget.dataset.preventSubmit = "true";
      this.submitButtonTarget.classList.add("btn-lock");
      this.submitButtonTarget.disabled = true;
    }
  }

  connect() {
    this.formTarget.addEventListener("submit", (e) => {
      if (this.formTarget.dataset.preventSubmit === "true") {
        e.preventDefault();
      }
    });
  }
}

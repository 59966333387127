import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["listItem"]

  connect() {
    this.listItemTargets.forEach((el) => {
      el.classList.add('animate-in');
    });
  }

  listItemTargetConnected(el) {
    el.classList.add('animate-in');
  }

  listItemTargetDisconnected(el) {
    el.classList.add('animate-out');
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["searchForm", "submitForm", "loadMoreLink", "suggestProvidersContainer", "suggestProviders"]
  isLoading = false;

  connect() {
    this.userSelectRole();
    this.scrollbarClasses();
  }

  suggestProvidersTargetConnected() {
    this.setClasses($('.scrollbar'));
    if (!this.hasLoadMoreLinkTarget) return;

    const container = this.suggestProvidersContainerTarget;
    container.addEventListener('scroll', () => {
      // Depending on the height of the container, it is 633px and the offset height is 550px, the threshold is 83px.
      // Because the scroll starts from 1px, we must subtract 1 from the threshold. Then the threshold must be less than 82px.
      const threshold = 80;
      const position = container.scrollTop + container.offsetHeight;
      const height = container.scrollHeight;

      if (!this.isLoading && position >= height - threshold) {
         this.loadMore();
      }

      if (position < height - threshold) {
        this.isLoading = false;
      }
    });
  }

  loadMore() {
    if (this.hasLoadMoreLinkTarget && !this.isLoading) {
      this.isLoading = true;
      this.loadMoreLinkTarget.click();
    }
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const searchQuery = this.searchFormTarget.querySelector('input[name="search"]').value;
      if (this.hasLoadMoreLinkTarget) {
        const url = new URL(this.loadMoreLinkTarget.href);
        url.searchParams.set('search', searchQuery);
        this.loadMoreLinkTarget.href = url.toString();
      }

      this.searchFormTarget.requestSubmit();
    }, 300);
  }


  handleSubmitForm() {
    const selectedProvider = document.querySelector('input[name="user[suggestion_provider_id]"]:checked');
    if (selectedProvider) {
      document.getElementById('suggestion-provider-id-field').value = selectedProvider.value;
    }
    this.submitFormTarget.requestSubmit();
  }


  userSelectRole() {
    $('.user_select_role').click(function(e) {
      if (!$(e.target).is('input')) {
        $('input:radio', this).trigger('click');
      }
    });
  }

  scrollbarClasses() {
    this.setClasses($('.scrollbar'));
    $('.scrollbar').on('scroll', event => {
      this.setClasses($(event.currentTarget));
    });
  }

  setClasses(el) {
    const isScrollable = el[0].scrollHeight > el[0].clientHeight;

    if (!isScrollable) {
      el.removeClass('is-bottom-overflowing is-top-overflowing');
      return;
    }

    const isScrolledToBottom = el[0].scrollHeight < el[0].clientHeight + el[0].scrollTop + 1;
    const isScrolledToTop = isScrolledToBottom ? false : el[0].scrollTop === 0;
    el.toggleClass('is-bottom-overflowing', !isScrolledToBottom);
    el.toggleClass('is-top-overflowing', !isScrolledToTop);
  }
}

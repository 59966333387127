import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropzone", "referredProviderDzItems", "providerSearchInput", "addEmail", "notFoundItem"];

  connect() {
    this.toggleSentInvitesVisibility();
  }

  searchProviders(e) {
    setTimeout(() => {
      const searchText = e.target.value;

      if (!searchText.length) {
        this.referredProviderDzItemsTarget.innerHTML = "";
        this.notFoundItemTarget.classList.add("d-none");
        return;
      }

      this.dropzoneTarget.classList.remove("d-none");

      $.ajax({
        url: `/referrals/provides_can_be_share`,
        type: "GET",
        dataType: "HTML",
        data: { search_text: searchText, referral_id: this.dropzoneTarget.dataset.referralId },
        success: (data) => {
          if (data) {
            this.referredProviderDzItemsTarget.classList.remove("d-none");
            this.notFoundItemTarget.classList.add("d-none");
            this.referredProviderDzItemsTarget.innerHTML = data;
          } else {
            this.referredProviderDzItemsTarget.classList.add("d-none");
            this.notFoundItemTarget.classList.remove("d-none");
            this.addEmailTarget.innerHTML = "Add " + searchText;
          }
        },
      });
    }, 300);
  }

  addNewProviderNotExist(e) {
    $.ajax({
      url: `/shared_referrals/add_provider`,
      type: "POST",
      dataType: "HTML",
      data: {
        email: this.providerSearchInputTarget.value,
        is_without_user: true
      },
      success: (data) => {
        $('#without_user').append(data);
        this.toggleSentInvitesVisibility();
        this.providerSearchInputTarget.value = "";
      },
    });
  }

  selectProvider(e) {
    $.ajax({
      url: `/shared_referrals/add_provider`,
      type: "POST",
      dataType: "HTML",
      data: {
        provider_id: e.currentTarget.dataset.providerId,
      },
      success: (data) => {
        $('#with_user').append(data);
        this.toggleSentInvitesVisibility();
        this.providerSearchInputTarget.value = "";
      },
    });
  }

  toggleSentInvitesVisibility() {
    const hasProviders = document.querySelector('#with_user').childElementCount > 0;
    const hasEmails = document.querySelector('#without_user').childElementCount > 0;

    const sentInvitesElement = document.querySelector('#sent-invites');
    const sharedReferralSubmitButton = document.querySelector('#shared-referral-submit-button');

    if (hasProviders || hasEmails) {
      if (sentInvitesElement) sentInvitesElement.classList.remove('d-none');
      if (sharedReferralSubmitButton) sharedReferralSubmitButton.classList.remove('btn-lock');
    } else {
      if (sentInvitesElement) sentInvitesElement.classList.add('d-none');
      if (sharedReferralSubmitButton) sharedReferralSubmitButton.classList.add('btn-lock');
    }
  }

  removeSelectingProvider(e) {
    e.currentTarget.closest('.shared-provider').remove();
    this.toggleSentInvitesVisibility();
  }

  providerSearchInputTargetConnected(e) {
    e.addEventListener("focusout", (e) => {
      setTimeout(() => {
        this.dropzoneTarget.classList.add("d-none");
      }, 200);
    });
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkBox" , "urgent", "routine"]

  connect() {
    this.checkboxChange();
  }

  checkboxChange() {
    if (this.checkBoxTarget.checked) {
      this.urgentTarget.removeAttribute('disabled');
      this.routineTarget.setAttribute('disabled', true);
    } else {
      this.urgentTarget.setAttribute('disabled', true);
      this.routineTarget.removeAttribute('disabled');
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["firstName", "lastName", "email", "submit", "count"]

  connect() {
    this.invitedCount = parseInt(localStorage.getItem('invitedCount')) || 0;
    this.inviteStatus = localStorage.getItem('inviteStatus') || 'false';
    if (this.inviteStatus === 'true') {
      this.countTarget.style.display = 'block';
      this.countTarget.innerText = `${this.invitedCount} team member invited`;
    } else {
      this.countTarget.style.display = 'none';
    }
    this.checkInput()
  }

  checkInput() {
    const firstName = this.firstNameTarget.value
    const lastName = this.lastNameTarget.value
    const email = this.emailTarget.value

    if (firstName && lastName && email) {
      this.submitTarget.style.color = 'white'
      this.submitTarget.style.backgroundColor = '#F1616E'
    } else {
      this.submitTarget.style.color = '#00000080'
      this.submitTarget.style.backgroundColor = 'white'
    }
  }

  incrementCount() {
    this.invitedCount++;
    localStorage.setItem('invitedCount', this.invitedCount);
    localStorage.setItem('inviteStatus', 'true');
    this.countTarget.style.display = 'block';
    this.countTarget.innerText = `${this.invitedCount} team member invited`;
  }
}

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("@popperjs/core")
require("jquery")
require("@nathanvda/cocoon")

import "bootstrap"
import "@fortawesome/fontawesome-free/css/all";
import "controllers"
import "@hotwired/turbo-rails"
import 'flatpickr/dist/themes/light.css'

import { loadingSpinner } from "../helpers"
global.Rails = Rails;
global.$ = jQuery;
global.loadingSpinner = loadingSpinner;

// Import the specific modules you may need (Modal, Alert, etc)
import { Tooltip, Popover } from "bootstrap"

// The stylesheet location we created earlier
require("../stylesheets/application.scss")

// If you're using Turbolinks. Otherwise simply use: jQuery(function () {
document.addEventListener("turbo:load", () => {
    // Both of these are from the Bootstrap 5 docs
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
        return new Popover(popoverTriggerEl)
    })
})

window.addEventListener('beforeunload', (event) => {
  const confirmDiscardModal = document.querySelector('#confirmDiscardModal');

  if (localStorage.getItem('isShowModal') === 'true' && confirmDiscardModal) {
    event.preventDefault();
  }
});

document.addEventListener('turbo:before-visit', (event) => {
  const confirmDiscardModal = document.querySelector('#confirmDiscardModal');
  if (localStorage.getItem('isShowModal') === 'true' && confirmDiscardModal) {
    event.preventDefault();
    localStorage.setItem('redirectToPath', event.detail.url);
    confirmDiscardModal.click();
  }
});

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "currentPaymentMethod", "selectPaymentMethod", "newPaymentMethodButton", "newPaymentMethodForm" ]

  toggleSetPaymentMethod() {
    this.currentPaymentMethodTarget.classList.toggle('d-none')
    this.selectPaymentMethodTarget.classList.toggle('d-none')
  }

  toggleNewPaymentMethodForm() {
    this.newPaymentMethodFormTarget.classList.toggle('d-none')
    this.newPaymentMethodButtonTarget.classList.toggle('d-none')
  }
}

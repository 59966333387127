import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  timeoutId = null;

  connect() {
    this.timeoutId = null;
  }

  handleSubmitSignUpWaitlist(event) {
    event.preventDefault();

    let inputWrapper;
    let successText;
    const isFooter = event.target.classList.contains("is-footer");
    if (isFooter) {
      inputWrapper = event.target.closest('.email-group');
      successText = document.querySelector(".add-waitlist-success-footer");
    } else {
      inputWrapper = event.target.closest('.group-waitlist-email-input-wrapper');
      successText = inputWrapper.querySelector(`.add-waitlist-success-${window.innerWidth < 768 ? 'mobile' : 'desktop'}`);
    }

    const input = inputWrapper.querySelector("input");
    const button = inputWrapper.querySelector("button");

    if (!input.value.trim()) {
      alert("Please enter your email address");
      return;
    }

    this.setUIState("Submitting...", "#434462", "#EAEBEF", true, button, input);

    $.ajax({
      url: "/waitlist_signups",
      type: "POST",
      dataType: "json",
      data: { waitlist_signup: { email: input.value } },
      success: () => {
        this.handleResponse("You’ve been added to the waitlist! ✨", "#4168FF", "#FFFFFF", false, button, input, successText, isFooter);
        input.value = "";  // Clear input on success
      },
      error: (xhr) => {
        const errorMessage = xhr.responseJSON?.message || "Something went wrong. Please try again.";
        console.error("Sign Up Error:", errorMessage);
        this.handleResponse(errorMessage, "#4168FF", "#FFFFFF", true, button, input, successText, isFooter);
      }
    });
  }

  setUIState = (buttonText, buttonColor, backgroundColor, disable, button, input) => {
    button.textContent = buttonText;
    button.style.backgroundColor = buttonColor;
    input.style.backgroundColor = backgroundColor;
    button.disabled = disable;
    input.disabled = disable;
  }

  handleResponse = (message, buttonColor, inputColor, isError, button, input, successText, isFooter) => {
    this.setUIState(isFooter ? "Submit" : "Sign up for Waitlist", buttonColor, inputColor, false, button, input);
    successText.textContent = message;
    successText.style.color = isError ? "red" : "#4168FF";
    successText.classList.remove("d-none");

    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      successText.classList.add("d-none");
    }, 5000);
  }

  handleChangeInputFooter(event) {
    const input = event.target;
    const button = document.querySelector(".sign-up-waitlist-group-footer").querySelector("button");

    if (input.value.trim()) {
      button.disabled = false;
      button.style.color = "#fff";
      button.style.backgroundColor = "#4168FF";
    } else {
      button.disabled = true;
      button.style.color = "rgba(0, 0, 0, 0.5)";
      button.style.backgroundColor = "rgba(176, 176, 176, 0.5)";
    }
  }
}

import { Controller } from "@hotwired/stimulus";
import { checkFiles } from "../helpers";

export default class extends Controller {
  static targets = ["imagePreview", "spinnerLoading"];

  connect() {
    this.initialImage = this.imagePreviewTarget.src;
  }

  upload(event) {
    const { url, inputName, dataType } = event.params;
    const file = event.target.files[0];
    const [selectedFiles] = checkFiles([file]);
    const reader = new FileReader();

    if (!file) return;

    if (!selectedFiles.length) {
      event.target.value = '';
      return;
    }

    reader.onload = () => {
      this.imagePreviewTarget.src = reader.result;
    };

    this.imagePreviewTarget.style.opacity = 0.5;
    this.spinnerLoadingTarget.classList.remove('d-none');
    reader.readAsDataURL(file);

    const formData = new FormData();
    formData.append(inputName, file)

    $.ajax({
      url: url,
      type: 'PATCH',
      data: formData,
      dataType: dataType,
      processData: false,
      contentType: false,
      success: () => {
        this.imagePreviewTarget.style.opacity = 1;
        this.spinnerLoadingTarget.classList.add('d-none');
      },
      error: (error) => {
        console.error('Error updating avatar:', error);
        alert('Something went wrong. Please try again.');

        this.imagePreviewTarget.src = this.initialImage;
        this.imagePreviewTarget.style.opacity = 1;
        this.spinnerLoadingTarget.classList.add('d-none');
      }
    });
  }
}

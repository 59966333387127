import { Controller } from "@hotwired/stimulus"
import { checkFiles } from "../helpers"

export default class extends Controller {
  static targets = ["video", "videoContainer", "videoTitle", "viewedText", "videoTime", "imageInput", "imagePreviewContainer", "imagePreview", "uploadedText", "recipientFirstName", "recipientLastName", "recipientEmail", "submitRecipientButton", "inviteSentButton"];

  submitRecipientButtonTargetConnected() {
    this.setupFormListeners();
    this.checkFormFilled();
  }

  videoTargetConnected() {
    this.videoTarget.addEventListener('loadeddata', () => {
      this.videoTimeTarget.innerHTML = this.formatTime(this.videoTarget.duration);
    });
  }

  formatTime(time) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  setupFormListeners() {
    [this.recipientFirstNameTarget, this.recipientLastNameTarget, this.recipientEmailTarget].forEach(target => {
      target.addEventListener('keyup', () => this.checkFormFilled());
    });

    this.submitRecipientButtonTarget.addEventListener('click', () => {
      const submitFormAddReferralPartner = document.getElementById('submitFormAddReferralPartner');
      submitFormAddReferralPartner.click();
    });
  }

  checkFormFilled() {
    const isFormFilled = this.recipientFirstNameTarget.value && this.recipientLastNameTarget.value && this.recipientEmailTarget.value;
    this.toggleButtonState(isFormFilled);
  }

  toggleButtonState(isEnabled) {
    this.submitRecipientButtonTarget.disabled = !isEnabled;
    this.submitRecipientButtonTarget.style.backgroundColor = isEnabled ? '#F1616E' : '#fff';
    this.submitRecipientButtonTarget.style.color = isEnabled ? '#fff' : '#00000080';
  }

  handleSelectReferralPartner(event) {
    document.querySelectorAll('.list-group-item').forEach(item => {
      item.style.backgroundColor = '';
      const icon = item.querySelector('.fa-check');
      if (icon) icon.classList.replace('fa-check', 'fa-chevron-right');
    });

    const referralPartnerItem = event.currentTarget.closest('.list-group-item');
    const buttonSelect = referralPartnerItem.querySelector('.fa-chevron-right');
    referralPartnerItem.style.backgroundColor = '#F1616E26';
    buttonSelect.classList.replace('fa-chevron-right', 'fa-check');
  }

  submitRecipientButtonTargetConnected() {
    this.setupFormListeners();
    this.checkFormFilled();
  }

  setupFormListeners() {
    [this.recipientFirstNameTarget, this.recipientLastNameTarget, this.recipientEmailTarget].forEach(target => {
      target.addEventListener('keyup', () => this.checkFormFilled());
    });

    this.submitRecipientButtonTarget.addEventListener('click', () => {
      const submitFormAddReferralPartner = document.getElementById('submitFormAddReferralPartner');
      submitFormAddReferralPartner.click();
    });
  }

  checkFormFilled() {
    const isFormFilled = this.recipientFirstNameTarget.value && this.recipientLastNameTarget.value && this.recipientEmailTarget.value;
    this.toggleButtonState(isFormFilled);
  }

  toggleButtonState(isEnabled) {
    this.submitRecipientButtonTarget.disabled = !isEnabled;
    this.submitRecipientButtonTarget.style.backgroundColor = isEnabled ? '#F1616E' : '#fff';
    this.submitRecipientButtonTarget.style.color = isEnabled ? '#fff' : '#00000080';
  }

  handleSelectReferralPartner(event) {
    document.querySelectorAll('.list-group-item').forEach(item => {
      item.style.backgroundColor = '';
      const icon = item.querySelector('.fa-check');
      if (icon) icon.classList.replace('fa-check', 'fa-chevron-right');
    });

    const referralPartnerItem = event.currentTarget.closest('.list-group-item');
    const buttonSelect = referralPartnerItem.querySelector('.fa-chevron-right');
    referralPartnerItem.style.backgroundColor = '#F1616E26';
    buttonSelect.classList.replace('fa-chevron-right', 'fa-check');
  }

  playVideo() {
    this.videoTarget.style.display = 'block';
    this.videoContainerTarget.style.background = 'black';
    this.videoTitleTarget.style.display = 'none';
    this.videoTimeTarget.style.display = 'none';
    this.videoTarget.play();
    this.updateReviewIntroductionVideo();
  }

  previewImage(event) {
    const file = this.imageInputTarget.files[0];
    const [selectedFiles] = checkFiles([file]);
    if (!selectedFiles.length) {
      this.imageInputTarget.value = '';
      return;
    }

    const reader = new FileReader();
    const userId = event.currentTarget.getAttribute('data-dashboard-user-id-param');

    reader.onload = (e) => {
      this.imagePreviewTarget.src = e.target.result;
      document.getElementById('tmpAvatar').src = e.target.result;
      this.imagePreviewContainerTarget.style.display = 'block';
      this.uploadedTextTarget.style.display = 'block';

      this.updateAvatar(file, userId);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      this.imagePreviewTarget.src = '';
      this.imagePreviewContainerTarget.style.display = 'none';
    }
  }

  updateAvatar(file, userId) {
    const formData = new FormData();
    formData.append('user[avatar]', file);

    $.ajax({
      url: `/users/${userId}/update_avatar`,
      type: 'PATCH',
      data: formData,
      processData: false,
      contentType: false,
      success: function(data) {
        document.getElementById('tmpAvatar').src = data.avatar_url;
      },
      error: function(error) {
        console.error('Error updating avatar:', error);
        this.uploadedTextTarget.innerHTML = 'Error uploading image';
      }
    });
  }

  updateReviewIntroductionVideo() {
    let has_not_viewed_introduction_video = this.viewedTextTarget.classList.contains('d-none');

    this.videoTarget.addEventListener('timeupdate', () => {
      const currentTime = this.videoTarget.currentTime;
      const duration = this.videoTarget.duration;

      if (has_not_viewed_introduction_video && currentTime / duration > 0.5) {
        has_not_viewed_introduction_video = false;
        const viewedText = this.viewedTextTarget;

        $.ajax({
          url: `/users/update_viewed_introduction_video`,
          type: 'PATCH',
          data: { viewed_introduction_video: true },
          dataType: 'json',
          success: function(_data) {
            viewedText.classList.remove('d-none');
          },
          error: function(error) {
            console.error('Error updating viewed introduction video:', error);
          }
        });
      }
    })
  }

  editImage() {
    this.imageInputTarget.click();
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["planMonthly", "planYearly", "textMonthly", "textYearly"]

  toggleFields(event) {
    if (event.target.checked) {
      this.planMonthlyTargets.forEach((element) => element.classList.add('d-none'));
      this.planYearlyTargets.forEach((element) => element.classList.remove('d-none'));
      this.textMonthlyTarget.classList.add('text-black-25');
      this.textYearlyTarget.classList.remove('text-black-25');
    } else {
      this.planMonthlyTargets.forEach((element) => element.classList.remove('d-none'));
      this.planYearlyTargets.forEach((element) => element.classList.add('d-none'));
      this.textMonthlyTarget.classList.remove('text-black-25');
      this.textYearlyTarget.classList.add('text-black-25');
    }
  }
}

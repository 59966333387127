import { Controller } from "@hotwired/stimulus"
import { checkFiles } from "../helpers"

export default class extends Controller {
  static targets = [ "imageItem" ]

  connect(){
    document.querySelector('.btn-selectable').classList.add('btn-selected');
  }

  selectFeedbackType(pointerEvent) {
    const buttons = document.querySelectorAll('.btn-selectable');

    buttons.forEach(function(btn) {
      btn.classList.remove('btn-selected');
      btn.classList.add('btn-unselected');
    });

    pointerEvent.target.closest('.btn-selectable').classList.remove('btn-unselected');
    pointerEvent.target.closest('.btn-selectable').classList.add('btn-selected');
    pointerEvent.target.checked = true;
  }

  countCharacters(pointerEvent) {
    const count = pointerEvent.target.value.length;

    document.getElementById('feedbackCharacterCount').innerText = count + "/750";
  }

  uploadImages(e){
    const files = e.target.files;
    const [selectedFiles, dataTransferFiles] = checkFiles(files);
    const selectingFilesAmount = files.length;
    const existingFileAmount = $('#feedbackImageContainer .file-wrapper input').length;

    if (existingFileAmount + selectingFilesAmount > 3) {
      alert("You can only upload a maximum of 3 files")
      e.target.value = '';
      return;
    }

    selectedFiles.forEach((file, index) => {
      const fileSizeMB = (file.size / (1024*1024)).toFixed(2);
      const fileWrapperEl = $('<div class="file-wrapper d-flex align-items-center" style="border: 1px solid #cccccc; border-radius: 8px; height: 70px; margin-top: 20px;" data-feedback-target="imageItem"></div>')
      $('#feedbackImageContainer').append(fileWrapperEl.
        append($('<div class="flex-grow-1 ps-2"></div>').append($('<div class="file-name"></div>').text(file.name)).append($('<div class="file-size" style="color: #808080"></div>').text(fileSizeMB + ' MB'))).
        append($('<div class="close-wrapper pe-2 d-flex"><i class="icon-close-image p-2" data-action="click->feedback#removeImage" ></i></div>'))
      );

      const hiddenField = document.createElement('input');
      hiddenField.setAttribute("type", "file");
      hiddenField.setAttribute("class", "d-none");
      hiddenField.name = e.target.name;
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(dataTransferFiles[index]);
      hiddenField.files = dataTransfer.files;
      fileWrapperEl.append(hiddenField);
    })
    // We will use these hidden input fields above to submit the files to the server instead of
    // the original input field, so we need to clear the original input field.
    e.target.value = '';
  }

  removeImage(e){
    e.currentTarget.closest('.file-wrapper').remove()
  }
}

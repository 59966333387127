import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fileUploadInput", "submitButton", "referralFileUploadContainer"]

  fileUploadInputTargetConnected(element) {
    if (element.dataset.triggerClick === 'true' && this.hasFileUploadInputTarget) this.fileUploadInputTarget.click()
  }

  onSubmitButtonClick(_event) {
    if (!(this.hasSubmitButtonTarget && this.hasReferralFileUploadContainerTarget)) return
    if (this.referralFileUploadContainerTarget.querySelectorAll('.file-wrapper').length === 0) return

    this.submitButtonTarget.querySelector('.save').classList.add('d-none')
    this.submitButtonTarget.querySelector('.uploading').classList.remove('d-none')
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["closeFlashBtn", "submitButton"]

  closeFlashBtnTargetConnected() {
    if (this.hasCloseFlashBtnTarget && document.querySelector('#newReferralFormSubmitButton')) {
      document.querySelector('#newReferralFormSubmitButton').disabled = false;
    }
    
    setTimeout(() => {
      if(this.hasCloseFlashBtnTarget) this.closeFlashBtnTarget.click();
    }, 3000)
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "notification", "scrollContainer" ]

  connect() {
    this.scrollHandler = this.checkVisibleAndMarkRead.bind(this);
    this.scrollContainerTarget.addEventListener('scroll', this.scrollHandler);
  }

  disconnect() {
    this.scrollContainerTarget.removeEventListener('scroll', this.scrollHandler);
  }

  checkVisibleAndMarkRead() {
    this.notificationTargets.forEach((notification) => {
      if (this.isInViewport(notification)) {
        const id = notification.dataset.notificationIdParam;
        $.ajax({
          method: "PATCH",
          url: `/notifications/${id}`,
          success: function(data) {
            if ($(notification).closest('li').hasClass('active')) {
              $('.number_notification').html(parseInt($('.number_notification').html()) - 1)
              $(notification).closest('li').removeClass('active')
              if ($('.number_notification').html() == '0') {
                $('.number_notification').hide();
              }
            }
          }
        });
      }
    });
  }

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    const parentRect = this.scrollContainerTarget.getBoundingClientRect();
    return (
      rect.top >= parentRect.top &&
      rect.left >= parentRect.left &&
      rect.bottom <= parentRect.bottom &&
      rect.right <= parentRect.right
    );
  }

  markRead(e){
    const { params: { id } } = e;
    $.ajax({
      method: "PATCH",
      url: `/notifications/${id}`,
      success: function(data) {
        if ($(e.target).closest('li').hasClass('active')) {
          $('.number_notification').html(parseInt($('.number_notification').html()) - 1)
          $(e.target).closest('li').removeClass('active')
        }
      }
    });
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "submitButton",
    "recipientProvider",
    "recipientProviderBlock",
    "formField",
    "checkbox",
    "imageContainer",
    "withImages",
    "withoutImages",
    "otpCodeModal",
    "form",
    "senderEmail",
    "otpInput",
    "provider"
  ];

  connect() {
    this.updateSubmitButtonState();
    this.formFieldTargets.forEach(target => {
      target.addEventListener('input', () => this.updateSubmitButtonState());
    });
    this.checkboxTargets.forEach(target => {
      target.addEventListener('change', () => this.updateSubmitButtonState());
    });
    this.checkFileUploads();
    this.expanded=false
  }

  toggle(event){
    event.preventDefault()
    this.expanded = !this.expanded

    this.providerTargets.forEach((provider, index) => {
      if (index >= 4) {
        provider.classList.toggle("d-none", !this.expanded)
      }
    })

      event.currentTarget.textContent = this.expanded
        ?  "Show less"
        : `+${this.providerTargets.length - 4} more`
  }

  showRecipientProvider() {
    this.recipientProviderTarget.value = ''
    this.recipientProviderBlockTarget.classList.remove('d-none')
  }

  hideRecipientProvider() {
    this.recipientProviderTarget.value = ''
    this.recipientProviderBlockTarget.classList.add('d-none')
  }

  updateSubmitButtonState() {
    const areAllFieldsFilled = this.formFieldTargets.every(field => field.value.trim() !== '');
    const areAllCheckboxesChecked = this.checkboxTargets.every(checkbox => checkbox.checked);

    const isAnyProviderSelected = document.getElementById('referral_any_provider').checked;

    if (isAnyProviderSelected) {
      if (areAllCheckboxesChecked) {
        this.submitButtonTarget.classList.remove('btn-lock');
      } else {
        this.submitButtonTarget.classList.add('btn-lock');
      }
    } else {
      if (areAllFieldsFilled && areAllCheckboxesChecked) {
        this.submitButtonTarget.classList.remove('btn-lock');
      } else {
        this.submitButtonTarget.classList.add('btn-lock');
      }
    }
  }

  checkFileUploads() {
    const fileInputs = this.imageContainerTarget.querySelectorAll('.file-wrapper input[type="file"]');
    const hasFiles = Array.from(fileInputs).some(input => input.files.length > 0);

    if (hasFiles) {
      this.withImagesTarget.style.display = 'block';
      this.withoutImagesTarget.classList.add('d-none');
    } else {
      this.withImagesTarget.style.display = 'block';
      this.withoutImagesTarget.classList.add('d-none');
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const fileInputs = this.imageContainerTarget.querySelectorAll('.file-wrapper input[type="file"]');
    const hasFiles = Array.from(fileInputs).some(input => input.files.length > 0);

    if (!hasFiles) {
      this.withImagesTarget.style.display = 'none';
      this.withoutImagesTarget.classList.remove('d-none');
    } else {
      e.target.closest('form').requestSubmit();
    }
  }

  handleFileChange(e) {
    this.checkFileUploads();
  }

  handleNo(e) {
    this.withImagesTarget.style.display = 'block';
    this.withoutImagesTarget.classList.add('d-none');
  }

  handleYes(e) {
    const email = document.getElementById('officeEmail').value;
    const firstName = document.getElementById('referringProviderFirstName').value;
    const lastName = document.getElementById('referringProviderLastName').value;
    const referrToAnyProvider = document.getElementById('referral_any_provider').checked;

    if(referrToAnyProvider) document.getElementById('referral_referred_provider_id').value = ""

    $.ajax({
      url: '/forms/inbound_referrals/verify_external_provider_email',
      type: 'POST',
      dataType: 'json',
      data: {
        email: email,
        first_name: firstName,
        last_name: lastName
      },
      success: (response) => {
        if(response?.result == 'success' && response?.message == 'verified') {
          this.formTarget.requestSubmit();
          return;
        }

        $(this.otpCodeModalTarget).modal('show').on('shown.bs.modal', () => {
          this.otpInputTargets.forEach(input => input.value = '');
          this.senderEmailTarget.innerText = email;
          this.otpInputTargets[0].focus();
        });
      },
      error: (response) => {
        alert('Error: ' + response.responseJSON?.message);
      }
    });
  }

  next(event) {
    const input = event.target;
    const nextInput = input.nextElementSibling;
    const prevInput = input.previousElementSibling;

    if (
      event.inputType === "deleteContentBackward" &&
      !input.value &&
      prevInput &&
      prevInput.classList.contains("otp-input")
    ) {
      prevInput.select();
    } else if (
      input.value &&
      nextInput &&
      nextInput.classList.contains("otp-input")
    ) {
      nextInput.select();
    }
  }

  paste(event) {
    const input = event.target;
    const pastedData = (event.clipboardData || window.clipboardData).getData('text');
    if (/^\d{6}$/.test(pastedData)) {
      const inputs = input.closest('form').querySelectorAll('.otp-input');
      inputs.forEach((field, index) => {
        field.value = pastedData[index];
      });
      inputs[5].focus();
      event.preventDefault();
    }
  }

  verifyOTPCode(event) {
    const inputs = Array.from(document.querySelectorAll(".otp-input"));
    const otp = inputs.map((input) => input.value).join("");
    if (otp.length === 6) {
      this.formTarget.querySelector('input[name="otp_code"]').value = otp;
      this.formTarget.requestSubmit();
    } else {
      alert("Please enter all 6 digits");
    }
  }
}

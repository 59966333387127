import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['nextBtn'];

  connect() {
  }

  nextBtnTargetConnected(){
    const currentStep = this.nextBtnTarget.getAttribute('data-step');
    let previousStep;

    switch(currentStep) {
      case 'patient':
          previousStep = 'new';
          break;
      case 'details':
          previousStep = 'patient';
          break;
      default:
          previousStep = '';
          break;
  }
    const currentStepElement = $(`.step-${currentStep}`);
    const previousStepElement = $(`.step-${previousStep}`);
    const firstStepElement = $(`.step-new`);

    if (currentStep == 'patient')
      {
        currentStepElement.addClass('active-step')
        previousStepElement.next().css('background-color','#28a745')
        previousStepElement.find('i').css('visibility', 'hidden');
      }
    else if (currentStep == 'details') {
      {
        currentStepElement.addClass('active-step')
        firstStepElement.find('i').css('visibility', 'hidden');
        firstStepElement.next().css('background-color','#28a745');
        previousStepElement.addClass('active-step');
        previousStepElement.next().css('background-color','#28a745');
        previousStepElement.find('i').css('visibility', 'hidden');
      }
    }
  }
}
import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = [ 'arrow', 'navBar']

  connect() {
    $(document).on('click', (event) => {
      setTimeout(() => {
        if($('.dropdown-menu').hasClass('show')){
          $(this.arrowTarget).removeClass('fa-angle-right')
          $(this.arrowTarget).addClass('fa-angle-down')
        } else {
          $(this.arrowTarget).removeClass('fa-angle-down')
          $(this.arrowTarget).addClass('fa-angle-right')
        }
      }, 50)
    })
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dashboard"];

  connect() {
    $("tr[data-link]").click(function () {
      $.ajax({
        dataType: "script",
        url: $(this).data("link"),
      });
    });
  }

  dashboardTargetConnected() {
    if (window.location.search.includes("get_referral_modal")) {
      const urlParams = new URLSearchParams(window.location.search);
      const referralId = urlParams.get("get_referral_modal");
      if (referralId) {
        $.ajax({
          url: ` /referrals/${referralId}/get_referral_modal`,
          method: "GET",
          remote: true,
          success: function (data) {},
        });
      }
    }

    $('#snooze_referral_toggle').on('change', (e) => {
      const form = $(e.target).closest('form')[0];
      form?.requestSubmit();
    })
  }

  changeProvider(e) {
    const value = e.target.innerText;
    $(`#modal_show_referral #dropdownMenuProvider`)[0].innerHTML = value;
  }

  handleClose() {
    $(`#modal_show_referral`).on("hidden.bs.modal", function () {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('referral_id');
      urlParams.delete('get_referral_modal');
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
      window.location.reload();
    });
  }

  handleOpen(e) {
    if($(e.target).hasClass('has-action') || $(e.target.closest('.has-action')).length) {
      return;
    }

    const {
      params: { id, url, direction, read, notificationId },
    } = e;

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('referral_id', id);
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);

    if (url) {
      $.ajax({
        method: "POST",
        url: url,
        success: function (data) {},
      });
    }

    $.ajax({
      url: `/referrals/${id}/get_referral_modal`,
      data: { direction: direction, read: read, notification_id: notificationId},
      method: "GET",
      remote: true,
      success: function (data) {},
    });
  }

  snoozeReferral(e) {
    e.preventDefault();

    let {
      params: { id, date },
    } = e;

    if(date == 'Custom') {
      date = $('#snooze_reminder_date').val()
    } else{
      let dateToAdd = 1;
      switch(date) {
        case('1 Day'): dateToAdd = 1; break;
        case('1 Week'): dateToAdd = 7; break;
        case('2 Weeks'): dateToAdd = 14; break;
        case('1 Month'): dateToAdd = 30; break;
        default: dateToAdd = 365; break;
      }
      const _date = new Date();
      _date.setDate(_date.getDate() + dateToAdd)
      date = this.getFormattedDate(_date)
    }

    if(!date) return;

    $.ajax({
      url: `/referrals/${id}/snooze_referral`,
      data: { snoozed_until: date },
      method: "PUT",
      remote: true,
      success: function (data) {
        if(data.status == 'success') {
          $('.snooze-button[data-referral-id-param=' +id+ '] > i').addClass('text-danger fa-solid')
        }
        $('#modal_snooze_referral_reminder').modal('hide')
      },
    });

  }

  showSnoozeCustomReminder(e) {
    e.preventDefault();
    const {
      params: { id, date },
    } = e

    $('#modal_snooze_referral_reminder').modal('show');
    $('#modal_snooze_referral_reminder button').attr('data-referral-id-param', id);
  }

  getFormattedDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return year + '-' + month + '-' + day;
  }

  show(event) {
    if ($(event.target).closest('.snooze-menu').length) {
      // Prevent the default behavior for snooze menu clicks
      event.preventDefault();
    } else {
      const { path } = event.params;
      const link = document.createElement("a");
      link.href = path;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  submitFormReassignProvider(event) {
    const { status, type, referralId } = event.target.dataset;

    if (status === 'declined') {
      if (type === 'referred_provider') {
        const referring_provider_id = $('#referral_referring_provider_id').val()
        const referred_provider_id = $('#referral_referred_provider_id').val()
        window.location.href = `/referrals/new?referring_provider_id=${referring_provider_id}&referred_provider_id=${referred_provider_id}&referring_referral_id=${referralId}`;
      }
      return;
    }

    event.target.closest('form').submit()
    setTimeout(() => {
      window.location.reload();
    },2000)
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'questionInitialWrapper',
    'softwareRequestedWrapper',
    'suggestionSubmittedWrapper',
    'form',
    'softwareRequestManually',
    'softwareRequestedInput',
  ]

  toggleRequestedSoftwareInput () {
    this.softwareRequestedWrapperTarget.classList.toggle('d-none');
    this.questionInitialWrapperTarget.classList.toggle('d-none');
    this.setSoftwareRequestManuallyStyles();
  }

  handleSubmitEnd(event) {
    if (event.detail.success) {
      this.displaySuccessMessage();
    }
  }

  displaySuccessMessage() {
    this.softwareRequestedWrapperTarget.classList.add('d-none');
    this.suggestionSubmittedWrapperTarget.classList.remove('d-none');
    setTimeout(() => {
      this.resetFormAndView();
    }, 3000);
  }

  resetFormAndView() {
    this.formTarget.reset();
    this.questionInitialWrapperTarget.classList.remove('d-none');
    this.suggestionSubmittedWrapperTarget.classList.add('d-none');
    this.setSoftwareRequestManuallyStyles();
  }

  setSoftwareRequestManuallyStyles() {
    if (!this.softwareRequestedWrapperTarget.classList.contains('d-none')) {
      this.softwareRequestManuallyTarget.classList.add('checked');
      this.softwareRequestedInputTarget.focus();
    } else {
      this.softwareRequestManuallyTarget.classList.remove('checked');
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "accountQuestion",
    "plansBillingQuestion",
    "generalQuestion",
    "complianceQuestion",
    "accountButton",
    "plansBillingButton",
    "generalButton",
    "complianceButton"
  ];

  connect() {
    this.showAccountQuestion();
  }

  showAccountQuestion() {
    this.hideAllQuestions();
    this.accountQuestionTarget.classList.remove("d-none");
    this.activateButton(this.accountButtonTarget);
  }

  showPlansBillingQuestion() {
    this.hideAllQuestions();
    this.plansBillingQuestionTarget.classList.remove("d-none");
    this.activateButton(this.plansBillingButtonTarget);
  }

  showGeneralQuestion() {
    this.hideAllQuestions();
    this.generalQuestionTarget.classList.remove("d-none");
    this.activateButton(this.generalButtonTarget);
  }

  showComplianceQuestion() {
    this.hideAllQuestions();
    this.complianceQuestionTarget.classList.remove("d-none");
    this.activateButton(this.complianceButtonTarget);
  }

  hideAllQuestions() {
    this.accountQuestionTarget.classList.add("d-none");
    this.plansBillingQuestionTarget.classList.add("d-none");
    this.generalQuestionTarget.classList.add("d-none");
    this.complianceQuestionTarget.classList.add("d-none");
  }

  activateButton(button) {
    [this.accountButtonTarget, this.plansBillingButtonTarget, this.generalButtonTarget, this.complianceButtonTarget].forEach(btn => {
      btn.classList.remove("active");
    });

    button.classList.add("active");
  }
}
